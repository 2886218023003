import React, { useEffect, useState } from 'react';

import { FormControl, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';

import Loader from '../../../../components/Loader';
import useFormStyles from '../../../../hooks/useFormStyles';
import { IDynamicProviderSettingsCreateFormProps } from '../../../../types';
import { useProviderSettingsSchema } from '../../../../api/paymentGateway';
import { IPaymentProvider, usePaymentProvidersList } from '../../../../api/paymentProviders';
import DynamicProviderSettingsCreateForm from './DynamicProviderSettingsCreateForm';

interface IProviderSettingsCreateForm
  extends Omit<IDynamicProviderSettingsCreateFormProps, 'provider' | 'schema'> {}

export default function ProviderSettingsCreateForm({ ...props }: IProviderSettingsCreateForm) {

  const formClasses = useFormStyles();

  const [selectedProvider, setSelectedProvider] = useState('APPLEPAY');

  const [{ items, error: paymentProvidersError }, loadingProviders, loadProviders] = usePaymentProvidersList();
  const [{ data: providerSettingsSchema, error }, loadingSchema, loadSchema] = useProviderSettingsSchema();

  const mappedProviderList = items.map((provider: IPaymentProvider) => provider?.id);

  useEffect(()=> {
    loadProviders({});
  },[]);

  useEffect(()=> {
    if (selectedProvider) {
      loadSchema(selectedProvider);
    }
  },[selectedProvider]);

  const isSchemaNotFound =
    (error || paymentProvidersError || !providerSettingsSchema?.schema?.properties) &&
    !loadingSchema &&
    !loadingProviders;

  return (
    <>
      <div className={formClasses.row}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="select-payment-provider">Payment Provider</InputLabel>
          <Select
            fullWidth
            value={selectedProvider}
            labelId="select-payment-provider"
            label="Payment Provider"
          >
            {['APPLEPAY', ...mappedProviderList].map((provider: string) => 
              <MenuItem key={provider} onClick={() => setSelectedProvider(provider)} value={provider}>
                {provider}
              </MenuItem>)}
          </Select>
        </FormControl>
      </div>

      <Loader loading={loadingSchema || loadingProviders} width={560} />

      {isSchemaNotFound && <Typography variant="h6" color="primary">Schema not found</Typography>}

      {providerSettingsSchema?.schema?.properties && !loadingSchema &&
        <DynamicProviderSettingsCreateForm 
          provider={selectedProvider} 
          schema={providerSettingsSchema.schema}
          { ...props }
        />}
    </>
  );
}