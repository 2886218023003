import React, { useEffect } from 'react';

import { makeStyles, Typography } from '@material-ui/core';

import TXLimitForm from './Limits/TXLimitForm';
import Loader from '../../../components/Loader';
import { ITXLimit, useCustomerLimits, useUpdateCustomerLimits } from '../../../api/customers';
import Descriptor from '../../../components/Descriptor';

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: 4,
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
}));

interface IProps {
  id: string;
  isMerchant: boolean;
}

export default function CustomerLimitsTab({ id, isMerchant }: IProps) {
  const classes = useStyles();
  const [{ items }, loading, load] = useCustomerLimits();
  const [save, saving] = useUpdateCustomerLimits((res: any) => {
    if (res.success)  {
      load(id);
    }
  });

  useEffect(() => {
    load(id);
  }, [id]);

  const COMMON_LIMITS = [
    items.find((i: ITXLimit) => i.period === 'DAY'),
    items.find((i: ITXLimit) => i.period === 'WEEK'),
    items.find((i: ITXLimit) => i.period === 'MONTH'),
  ];

  const OBI_LIMITS = [
    items.find((i: ITXLimit) => i.period === 'OBI_DAY'),
    items.find((i: ITXLimit) => i.period === 'OBI_WEEK'),
    items.find((i: ITXLimit) => i.period === 'OBI_MONTH'),
  ];

  return (
      <Loader loading={loading}>
        {COMMON_LIMITS.map((i: ITXLimit, index: number) =>
            <TXLimitForm limit={i} id={id} save={save} saving={saving} key={index} isMerchant={isMerchant} />
        )}
        <Descriptor group="limits" title="OBI">
          <Typography variant="body1" color="primary" className={classes.title}>
            OBI Limits
          </Typography>
        </Descriptor>
        {OBI_LIMITS.map((i: ITXLimit, index: number) =>
            <TXLimitForm limit={i} id={id} save={save} saving={saving} key={index} isOBI isMerchant={isMerchant} />
        )}
      </Loader>
  );
}
