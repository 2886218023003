
import React, { useState, useEffect, useContext } from 'react';

import { useRouteMatch } from 'react-router';

import clsx from 'clsx';

import { Form } from 'react-final-form';

import { ACLType } from '../../../api/acl';
import Button from '@material-ui/core/Button';
import { IOrderItem, OrderStatus, OrderType, useUpdateOrderStatus } from '../../../api/order';
import { IPayment } from '../../../api/payment';
import BlockchainLink from '../../../components/BlockchainLink';
import { DataRow } from '../../../components/DataView';
import DateView from '../../../components/DateView';
import { Input, ProtectionInput } from '../../../components/Form';
import Label from '../../../components/Label';
import OrderStatusLabel from '../../../components/Label/OrderStatusLabel';
import OrderTypeLabel from '../../../components/Label/OrderTypeLabel';
import PopupLink from '../../../components/UI/PopupLink';
import ShortString from '../../../components/UI/ShortString';
import { getAcceptedBy } from '../../../helpers/customerHelper';
import { getMaskedCard } from '../../../helpers/maskHelper';
import { getPostbackStatusType } from '../../../helpers/orderHelper';
import useFormStyles from '../../../hooks/useFormStyles';
import ACLFastAdd, { ACLData } from '../../ACL/BlakWhite/ACLFastAdd';
import { useSeonChecks } from '../../../api/order';
import { useKycList } from '../../../api/kyc';
import Antifraud from './Antifraud';
import ResendPostbackForm from './ResendPostbackForm';
import { splitAddress } from '../../../helpers/addressHelper';
import { useLegalEntityOne } from '../../../api/legalEntity';
import { NavLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core';
import useMobileStyles from '../../../hooks/useMobileStyles';
import { useBreakpoints } from '../../../hooks/useBreakpoints ';
import { AbilityContext, Can } from '../../../acl/can';

const useStyles = makeStyles((theme) => ({
  root: {},
  gap: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
      gap: '10px',
      alignItems: 'flex-start',
    },
  },
}));


interface IProps {
  order: IOrderItem;
  payments: IPayment[];
  onSave: any;
  reloadOrderDetails: any;
}
export default function OrderOverview({ order, payments, reloadOrderDetails, onSave }: IProps) {
  const formClasses = useFormStyles();
  const mobileClasses = useMobileStyles();
  const isResolutionSevenHundred = useBreakpoints(700);
  const classes = useStyles();
  const match = useRouteMatch();
  const [acl, setAcl] = useState<ACLData | undefined>();
  const [changeStatus, setChangeStatus] = useState<'approve' | 'reject' | undefined>();

  const [{ items: seon }, loadingSeon, loadSeon] = useSeonChecks();
  const [kycData, loadingKys, loadKycData] = useKycList();
  const [{ data }, loading, load] = useLegalEntityOne();


  useEffect(() => {
    if (!seon[0]) {
      loadSeon({ orderId: order.id });
    }
    loadKycData({ filter: { customerId: order.acceptedById } });
  }, [order]);

  const [updateStatus, updating] = useUpdateOrderStatus((res: any) => {
    if (res.success) {
      setChangeStatus(undefined);
      onSave();
    }
  });

  useEffect(()=>{
    load(order.legalEntity);
  },[]);

  const baseUrl = `/${match.url.split('/')[1]}`;
  let err: string | undefined;
  if ([OrderStatus.ERROR, OrderStatus.EXPIRED].includes(order.status)) {
    if (order.status !== OrderStatus.EXPIRED) {
      const payment = payments.find((p) => !!p.error);
      const paymentError = payment?.error;
      const failedOperation = payment?.operations.find((o) => !o.success);
      err = failedOperation?.error || paymentError || order.error;
    } else if (order.error) {
      err = order.error;
    }
  }

  const [addressPart, memoTagPart] = splitAddress(order?.address);

  return (
    <>
      {acl && <ACLFastAdd onClose={() => setAcl(undefined)} value={acl.value} type={acl.type} white={acl.white} />}
      <Can I="read" a="merchant-stuff">
        <DataRow label="Legal Entity">{data?.name}</DataRow>
      </Can>
      <DataRow label="Type">
        <OrderTypeLabel type={order.type} />
      </DataRow>
      <DataRow label="Status">
        <div className={clsx(formClasses.row, isResolutionSevenHundred && mobileClasses.gap)} style={{ marginBottom: 0 }}>
          <OrderStatusLabel status={order.status} kycData={kycData} />
          { (order.status === OrderStatus.SUSPICIOUS
            || order.status === OrderStatus.EXPIRED
            || order.status === OrderStatus.PROCESSING) && (
            <>
              {changeStatus
                ? (
                  <Form
                    onSubmit={(values) => {
                      const isProcessingOrExpired =
                        order.status === OrderStatus.PROCESSING || order.status === OrderStatus.EXPIRED;
                      updateStatus(changeStatus, values, isProcessingOrExpired);
                    }
                  }
                    initialValues={{
                      id: order.id,
                      '2fa': ''
                    }}
                    render={({ handleSubmit }) => (
                      <form onSubmit={handleSubmit} className={formClasses.row} style={{ flex: '1 1 auto', alignItems: 'center', marginBottom: 0 }}>
                        <Input name="id" type="hidden" />
                        <ProtectionInput margin="none" />
                        <Button type="submit" variant="contained">{changeStatus}</Button>
                      </form>
                    )}
                  />
                ) : (
                  <div className={clsx(formClasses.row, isResolutionSevenHundred && mobileClasses.gap)}>
                    <Button onClick={() => setChangeStatus('approve')} variant="contained" color="primary" size="small">
                      Approve
                    </Button>
                    {order.status === OrderStatus.SUSPICIOUS && (
                    <Button onClick={() => setChangeStatus('reject')} variant="contained" color="secondary" size="small">
                      Reject
                    </Button>)}
                  </div>
                )
              }
            </>
          )}
        </div>
      </DataRow>
      <Antifraud data={seon} />
      {err && (
        <DataRow label="Error" copy={err}>
          <ShortString chars={50} text={err} />
        </DataRow>
      )}
      <DataRow label="Amount">{order.amount} {order.currency}</DataRow>
      <DataRow label="Payment amount">{order.paymentAmount} {order.paymentCurrency}</DataRow>
      <DataRow label="SID">{order.sid}</DataRow>
      <DataRow label="Send to merchant">{order.sendAmount ? `${order.sendAmount} ${order.currency}` : '-'}</DataRow>
      <DataRow label="Fees">
        {
          order.fee
            ? `deposit: ${order.fee.deposit};
          depositFixed: ${order.fee.depositFixed};
          rolling: ${order.fee.rolling};
          withdrawal: ${order.fee.withdrawal};
          withdrawalFixed: ${order.fee.withdrawalFixed}`
            : ''
        }
      </DataRow>
      <DataRow label="Customer">
        {order.acceptedById && (
          <div className={classes.gap}>
            <PopupLink popup="customer" id={order.acceptedById} baseUrl={baseUrl}>
              {getAcceptedBy(order)}
            </PopupLink>
            <Can I="manage" a="acl">
              <Button
                variant="contained"
                color="secondary"
                // @ts-ignore
                onClick={() => setAcl({ type: ACLType.EMAIL, value: getAcceptedBy(order) })}
                size="small"
              >
                Blacklist
              </Button>
              <Button
                variant="contained"
                // @ts-ignore
                onClick={() => setAcl({ type: ACLType.EMAIL, value: getAcceptedBy(order), white: true })}
                size="small"
              >
                Whitelist
              </Button>
            </Can>
          </div>
        )}
      </DataRow>
      <DataRow label="Ext. ID">{order.externalId}</DataRow>
      <DataRow label="Invoice ID">{order.invoiceId}</DataRow>
      <DataRow label="Instant payment schema">
        <Label label={order.instantPaymentScheme ? 'YES' : 'NO'} type={order.instantPaymentScheme ? 'success' : 'danger'} size="small" />
      </DataRow>
      <DataRow label="Payment group">
        <Link component={NavLink} onClick={(e:any) => e.stopPropagation()} to={`/settings/payment-groups/${order.paymentGroup}`}>{order.paymentGroup}</Link>
      </DataRow>
      {order.unDomain && <DataRow label="Unstoppable Domain">{order.unDomain}</DataRow>}
      <DataRow label="Address">
        <div className={classes.gap}>
          {order.type === OrderType.WALLET_TO_FIAT
            ? getMaskedCard(order.address)
            : <BlockchainLink charsLimit={30} type="address" value={addressPart} currency={order.currency} />
          }
          <Can I="manage" a="acl">
            <Button
              variant="contained"
              color="secondary"
              // @ts-ignore
              onClick={() => setAcl({
                type: order.type === OrderType.WALLET_TO_FIAT ? ACLType.CARD_PAN : ACLType.CRYPTO_ADDRESS,
                value: order.type === OrderType.WALLET_TO_FIAT ? order.address : addressPart,
              })}
              size="small"
            >
              Blacklist
            </Button>
            <Button
              variant="contained"
              // @ts-ignore
              onClick={() => setAcl({
                type: order.type === OrderType.WALLET_TO_FIAT ? ACLType.CARD_PAN : ACLType.CRYPTO_ADDRESS,
                value: order.type === OrderType.WALLET_TO_FIAT ? order.address : addressPart,
                white: true,
              })}
              size="small"
            >
              Whitelist
            </Button>
          </Can>
        </div>
      </DataRow>
      {memoTagPart && (
        <DataRow label="Memo/Tag">
          {memoTagPart}
        </DataRow>
      )}
      <Can I="view" a="order-details">
        <DataRow label="Requested by">
          <div className={classes.gap}>
            {order.requestedById && (
              <PopupLink popup="customer" id={order.requestedById} baseUrl={baseUrl}>
                {order.requestedById}
              </PopupLink>
            )}
            <span>{order?.requestedByEmail}</span>
          </div>
        </DataRow>
      </Can>
      <DataRow label="Conversion rate">{order.conversionRate}</DataRow>
      <DataRow label="Converted at"><DateView value={order.convertedAt} /></DataRow>
      {order.blockchainTxId && (
        <DataRow label="Blockchain tx. ID" copy={order.blockchainTxId}>
          <BlockchainLink charsLimit={50} value={order.blockchainTxId} type="tx" provider={order.provider} currency={order.currency} />
        </DataRow>
      )}
      <Can I="view" a="order-details">
        <DataRow label="Postback URL" copy={order.postbackUrl}>
          <ShortString chars={50} text={order.postbackUrl} />
        </DataRow>
        <DataRow label="Postback status">
          <div className={classes.gap}>
            {order.postbackStatus && <Label label={order.postbackStatus} type={getPostbackStatusType(order.postbackStatus)} size="small" />}
            <ResendPostbackForm orderId={order.id} reloadOrderDetails={reloadOrderDetails} />
          </div>
        </DataRow>
        <DataRow label="Postback sent at">
          {order.postbackSentAt && <DateView value={order.postbackSentAt} />}
        </DataRow>
        <DataRow label="Success URL" copy={order.successUrl}>
          <ShortString chars={50} text={order.successUrl} />
        </DataRow>
        <DataRow label="Fail URL" copy={order.failUrl}>
          <ShortString chars={50} text={order.failUrl} />
        </DataRow>
      </Can>
      {order.error && (
        <DataRow label="Error details">{order.error}</DataRow>
      )}
      <DataRow label="Created"><DateView value={order.createdAt} /></DataRow>
      <DataRow label="Updated"><DateView value={order.updatedAt} /></DataRow>
      {order.expireAt && <DataRow label="Expired"><DateView value={order.expireAt} /></DataRow>}
    </>
  );
}
