import { useState } from 'react';
import { offset, todayEnd, todayStart } from '../helpers/datesHelper';

// increase version on any filters logic changing to prevent using filters from localstorage
const version = '8';

export default function useFilter(page: string, defaultFilter = {}, withDates = true, withUpdateDates = false): [(filter: any) => void, any] {
  const excludePages = [
    'orders',
    'payments',
    'clearing',
    'blockchain',
    'rolling', 
    'customer_orders',
    'customers',
    'kyc',
    'bookkeeper_balance',
    'apiRequests',
    'settlements'
  ];
  const lsName = `${page}_filters_${version}`;
  const saved = !excludePages.includes(page) ? window.localStorage.getItem(lsName) : null;
  const initial = saved ? { ...defaultFilter, ...JSON.parse(saved) } : defaultFilter;
  if (withDates) {
    initial.dateTo = null;
    initial.dateFrom = null;
    initial.pickerViewDateTo = null;
    initial.pickerViewDateFrom = null;
  }

  if(withUpdateDates) {
    initial.updateDateTo = null;
    initial.updateDateFrom = null;
    initial.updatePickerViewDateFrom = null;
    initial.updatePickerViewDateTo = null;
  }

  if (withDates && page === 'orders') {
    initial.dateTo = todayEnd().valueOf() - offset;
    initial.dateFrom = todayStart().valueOf() - offset;
    initial.pickerViewDateTo = todayEnd().valueOf();
    initial.pickerViewDateFrom = todayStart().valueOf();
  }

  const [filter, setFilter] = useState(initial);
  
  function setFilterState(f: any) {
    const tmp: any = {};
    Object.keys(filter).forEach((k) => {
      if (f[k] === undefined || (typeof (f[k]) !== 'boolean' && f[k] !== null
        && !['dateFrom', 'dateTo', 'date1', 'date2', 'updateDateFrom', 'updateDateTo', 'pickerViewDateTo', 'pickerViewDateFrom', 'updatePickerViewDateTo', 'updatePickerViewDateFrom' ].includes(k) 
        && !f[k].length))
      {
        tmp[k] = undefined;
      } else {
        tmp[k] = f[k];
      }
    });
    const upd = { ...filter, ...tmp };
    const dateFrom = upd.date1 || upd.pickerViewDateFrom;
    if (dateFrom) {
      upd.dateFrom = dateFrom.valueOf() - offset;
      upd.pickerViewDateFrom = dateFrom.valueOf();
    } else {
      upd.dateFrom = null;
    }

    const dateTo = upd.date2 || upd.pickerViewDateTo;
    if (dateTo) {
      upd.dateTo = dateTo.valueOf() - offset;
      upd.pickerViewDateTo = dateTo.valueOf();
    } else {
      upd.dateTo = null;
    }
    
    const updateDateTo = upd?.updatePickerViewDateTo;
    const updateDateFrom = upd?.updatePickerViewDateFrom;
    if (withUpdateDates) {
      if (updateDateFrom) {
        upd.updateDateFrom = updateDateFrom.valueOf() - offset;
        upd.updatePickerViewDateFrom = updateDateFrom.valueOf();
      } else {
        upd.updateDateFrom = null;
      }
      if (updateDateTo) {
        upd.updateDateTo = updateDateTo.valueOf() - offset;
        upd.updatePickerViewDateTo = updateDateTo.valueOf();
      } else {
        upd.updateDateTo = null;
      }
    }

    if(!excludePages.includes(page)){
      window.localStorage.setItem(lsName, JSON.stringify(upd));
    }
    setFilter(upd);
  }

  return [setFilterState, filter];
}