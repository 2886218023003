import Api, { useFetchApi, useUpdateApi } from '.';

export interface IProviderSettings {
  id: string
  provider: string
  createdAt: number
  updatedAt: number
  merchantId?: string
  legalEntity?: string
  balanceFetchSchedule?: string
  config: Record<string, any>
}

export function useProviderSettingsList() {
  return useFetchApi(
    async () => Api.post('/payment/provider/properties/list', {}),
    { items: [] }
  );
}

export function useProviderSettingsSchema() {
  return useFetchApi(
    async (provider: string) => Api.post('/payment/provider/properties/schema', { provider }),
    { data: {} }
  );
}

export function useProviderSettingsCreate(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/payment/provider/properties/create', data),
    cb,
    'Provider settings have been successfully created'
  );
}

export function useProviderSettingsUpdate(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/payment/provider/properties/update', data),
    cb,
    'Provider settings have been successfully updated'
  );
}

export function useProviderSettingsDelete(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/payment/provider/properties/delete', data),
    cb,
    'Provider settings have been successfully deleted'
  );
}


