import React, { useContext, useEffect, useRef } from 'react';
import { Waypoint } from 'react-waypoint';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CardActions from '@material-ui/core/CardActions';
import EmptyList from '../../components/EmptyList';
import PaymentsList from './PaymentsList';
import Loader from '../../components/Loader';
import PaymetsFilter from './PaymetsFilter';
import useGlobalStyles from '../../components/Layout/useGlobalStyles';
import useFilter from '../../hooks/useFilter';
import { usePaymentsList } from '../../api/payment';
import useColumns, { IColumn } from '../../hooks/useColumns';
import {clearDatesIfIdsInFilter} from "../../helpers/filtersHelper";
import FilterWrapper, { ICollapseHandle } from '../../components/FilterWrapper/FilterWrapper';
import { AbilityContext } from '../../acl/can';

const baseColumns: IColumn[] = [
  { id: 'id', name: 'ID', active: true },
  { id: 'orderId', name: 'Order ID', active: true },
  { id: 'created', name: 'Created', active: true },
  { id: 'amount', name: 'Amount', active: true },
  { id: 'currency', name: 'Currency', active: true },
  { id: 'source', name: 'Source', active: true },
  { id: 'apm', name: 'APM', active: true },
  { id: 'method', name: 'Method', active: true },
  { id: 'provider', name: 'Provider', active: true },
  { id: 'status', name: 'Status', active: true },
  { id: 'finished', name: 'Finished', active: true },
  { id: 'operations', name: 'Operations', active: true },
  { id: 'updated', name: 'Updated', active: true },
];

const merchantColumns: IColumn[] = [
  { id: 'type', name: 'Type', active: true },
  { id: 'reqBy', name: 'RequestedById', active: true }
];

const finalColumnOrder = [
  'id', 'orderId', 'created', 'type', 'amount', 'currency', 'source',
  'apm', 'method', 'provider', 'status', 'finished', 'operations',
  'updated', 'reqBy'
];

function sortColumnsByOrder(columns: IColumn[]) {
  return columns.sort(
    (a, b) => finalColumnOrder.indexOf(a.id) - finalColumnOrder.indexOf(b.id)
  );
}

export default function PaymentsPage() {

  const [{ items, error, full }, loading, load] = usePaymentsList();
  const globalClasses = useGlobalStyles();
  const accordionRef = useRef<ICollapseHandle>(null);
  const globalCountry = useSelector((store:any) => store.appData.countries.globalCountry);

  const ability = useContext(AbilityContext);

  const defaultColumns = ability.can('read', 'merchant-stuff')
    ? sortColumnsByOrder([...baseColumns, ...merchantColumns])
    : sortColumnsByOrder([...baseColumns]);

  const emptyFilter = () => ({
    id: null,
    status: null,
    currency: null,
    provider: null,
    source: null,
    orderId: null,
    requestedBy: null,
    type: null,
    country: globalCountry || null,
  });

  const [setFilter, filter] = useFilter('payments', emptyFilter());
  const [columns, ColumnsFilter] = useColumns('payments', defaultColumns);

  useEffect(() => {
    if (filter.country !== globalCountry) {
      setFilter({
        ...filter,
        country: globalCountry,
      });
    }
  }, [globalCountry]);

  useEffect(() => {

    const reqFilters = clearDatesIfIdsInFilter({
      ...filter,
      paymentId: filter.id,
      id: undefined
    },['orderId', 'paymentId'])

    accordionRef.current?.collapse();

    load({
      filter: reqFilters
    });
  }, [filter])

  return (
    <Grid container spacing={3}>
      <FilterWrapper ref={accordionRef}>
        <PaymetsFilter filter={filter} onChange={setFilter} />
      </FilterWrapper>
      <Grid item className={globalClasses.table} style={{ width: '100%', minWidth: 'fit-content' }}>
        <Paper className={globalClasses.paper}>
          <CardActions>
            <Grid container justify="flex-end">
              <Grid item>
                <ColumnsFilter />
              </Grid>
            </Grid>
          </CardActions>
          {items.length > 0 ? <PaymentsList items={items} columns={columns} /> : <EmptyList error={error} loading={loading} />}
          <Loader loading={loading} />
          {!loading && !error && !full && <Waypoint onEnter={() => load({ cursor: items.length, filter })} />}
        </Paper>
      </Grid>
    </Grid>
  );
}
