import { useEffect } from 'react';

import { SettlementFees } from './SettlementFees';
import CurrenciesFeesForm from './CurrenciesFeesForm';

import CustomerFeesForm from '../Fees/CustomerFeesForm';
import Loader from '../../../../components/Loader';
import {
  ICustomer,
  ICustomerSettings,
  useCustomerFeesList,
  useCustomerFeeCreate,
  useCustomerFeeUpdate,
  useCustomerFutureFeesOne,
} from '../../../../api/customers';
import { useCurrenciesList } from '../../../../api/currency';
import CustomerFutureFeesForm from './CustomerFutureFeesForm/CustomerFutureFeesForm';

export interface IFeesFormProps {
  customer: ICustomer;
  updateSettings(data: ICustomerSettings): void;
}

export default function FeesForm({ customer, updateSettings }: IFeesFormProps) {
  const [{ items: fees }, loading, load] = useCustomerFeesList();
  const [{ items: currencies }, loadingCurrencies, loadCurrencies] = useCurrenciesList();
  const [{ data: futureFees }, loadingFutureFees, loadFutureFees] = useCustomerFutureFeesOne();

  const [create, creating] = useCustomerFeeCreate((res: any) => {
    if (res.success) {
      // setFeesForms({ ...feesForms, [res.data.currency]: res.data });
      load(customer.id);
    }
  });
  const [update, updating] = useCustomerFeeUpdate((res: any) => {
    if (res.success) {
      // setFeesForms({ ...feesForms, [res.data.currency]: res.data });
      load(customer.id);
    }
  });

  useEffect(() => {
    if (customer.id) {
      load(customer.id);
      loadFutureFees(customer.id);
      loadCurrencies();
    }
  }, [customer.id]);

  return (
    <Loader loading={loading || loadingCurrencies || loadingFutureFees} width={500}>
      <CustomerFeesForm customer={customer} fees={fees} restHandlers={{create, creating, update, updating, load}} />
      <br />
      <CustomerFutureFeesForm 
        customer={customer} 
        futureFees={futureFees} 
        loadFutureFees={loadFutureFees} 
      />
      <br />
      <SettlementFees customer={customer} updateSettings={updateSettings} />
      <br />
      <CurrenciesFeesForm  customer={customer} currencies={currencies} fees={fees} restHandlers={{create, creating, update, updating, load}} />
    </Loader>
  );
}
