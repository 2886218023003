import { FieldArray } from 'react-final-form-arrays';

import clsx from 'clsx';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';

import { IApmFee } from '../../../../../api/customers';
import { CurrencyType } from '../../../../../api/currency';
import useFormStyles from '../../../../../hooks/useFormStyles';
import { DEFAULT_APM_FEE } from '../../../../../constants/fees';
import ApmSelect from '../../../../../components/Form/ApmSelect';
import { CurrencySelect, Input } from '../../../../../components/Form';
import {
  required,
  mustBeNumber,
  composeValidators,
  lowRangeFeeValidator,
  highRangeFeeValidator,
} from '../../../../../validators';

const useStyles = makeStyles((theme) => ({
  wrapperAddButton: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  wrapperRemoveButton: {
    paddingTop: theme.spacing(2)
  },
  dynamicRow: {
    '& .MuiAutocomplete-inputRoot': {
      paddingTop: '2px !important',
      paddingBottom: '2px !important',
    }
  }
}));

export interface IApmFutureFeeRow extends IApmFee {
  apm: string;
}

interface IDynamicApmFeesFormProps {
  apmFee: object | undefined | IApmFutureFeeRow[]
}

function DynamicApmFeesForm({ apmFee }: IDynamicApmFeesFormProps) {

  const classes = useStyles();
  const formClasses = useFormStyles();

  return(
    <div key="apmFee">
      <FieldArray name="apmFee">
        {({ fields }) => (
          <div>
            {fields.map((name, index) => (
              <div key={name}>
                <div className={clsx(formClasses.row, classes.dynamicRow)}>
                  <ApmSelect
                    name={`${name}.apm`}
                    label="Apm"
                    margin="normal"
                    exclude={
                      apmFee && Array.isArray(apmFee)
                        ? apmFee
                            .filter((_, i) => i !== index)
                            .map((item) => item.apm)
                        : []
                    }
                    validate={required}
                  />
                  <Input
                    name={`${name}.deposit`}
                    label="Deposit"
                    type="search"
                    margin="normal"
                    autoComplete="xyz123"
                    validate={composeValidators(
                      required,
                      mustBeNumber,
                      lowRangeFeeValidator,
                    )}
                  />
                  <Input
                    name={`${name}.depositFixed`}
                    type="search"
                    margin="normal"
                    autoComplete="xyz123"
                    label="Deposit Fixed"
                    validate={composeValidators(
                      required,
                      mustBeNumber,
                      highRangeFeeValidator,
                    )}
                  />
                  <Input
                    name={`${name}.withdrawal`}
                    type="search"
                    margin="normal"
                    label="Withdrawal"
                    autoComplete="xyz123"
                    validate={composeValidators(
                      required,
                      mustBeNumber,
                      lowRangeFeeValidator,
                    )}
                  />
                  <Input
                    name={`${name}.withdrawalFixed`}
                    type="search"
                    margin="normal"
                    autoComplete="xyz123"
                    label="Withdrawal Fixed"
                    validate={composeValidators(
                      required,
                      mustBeNumber,
                      highRangeFeeValidator,
                    )}
                  />
                  <CurrencySelect
                    name={`${name}.fixedCurrency`}
                    label="Fixed fee currency"
                    type={CurrencyType.FIAT}
                    fullWidth={true}
                    margin="normal"
                    validate={required}
                  />
                  <Input
                    name={`${name}.depositFeeMin`}
                    type="search"
                    margin="normal"
                    autoComplete="xyz123"
                    label="Deposit Fee Min"
                    validate={composeValidators(
                      required,
                      mustBeNumber,
                      highRangeFeeValidator,
                    )}
                  />
                  <div className={classes.wrapperRemoveButton}>
                    <Button
                      type="button"
                      variant="contained"
                      color="secondary"
                      onClick={() => fields.remove(index)}
                    >
                      Remove
                    </Button>
                  </div>
                </div>
              </div>
            ))}
            <div className={formClasses.row}>
              <div className={classes.wrapperAddButton}>
                <Button
                  type="button"
                  variant="contained"
                  onClick={() => fields.push({ ...DEFAULT_APM_FEE, apm: '' })}
                >
                  Add APM Fee
                </Button>
              </div>
            </div>
          </div>
        )}
      </FieldArray>
    </div>
  );
}

export default DynamicApmFeesForm;