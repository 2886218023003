
import TableFilter from '../../components/Filter';
import { BoolSelect, Input } from '../../components/Form';

interface IFilter {
  customerId?: number;
  walletId?: number;
  unlocked?: boolean;
}

interface IWalletLocksFilterProps {
  filter: IFilter;
  onChange: (filter: IFilter) => void;
}

export default function WalletLocksFilter({ filter, onChange }: IWalletLocksFilterProps) {
  return (
    <TableFilter onApply={onChange} filter={filter}>
      <Input
        margin="none"
        name="customerId"
        label="Customer ID"
      />
      <Input
        margin="none"
        name="walletId"
        label="Wallet ID"
      />
      <BoolSelect
        fullWidth
        name="unlocked"
        label="Unlocked"
      />
    </TableFilter>
  );
}
