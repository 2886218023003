import Api, { useFetchApi, useUpdateApi } from './index';

export enum Algorithm {
   HMAC_SHA_1 = 'HMAC_SHA_1',
   HMAC_SHA_256 = 'HMAC_SHA_256',
   HMAC_SHA_512 = 'HMAC_SHA_512'
}

export class LegalEntity {
   id = '';
   name = '';
   seon = '';
   createdAt?: number;
   updatedAt?: number;
   sumSubSettings: SumSubSettings = {
      clientId: '',
      secret: '',
      token: '',
      algorithm: Algorithm.HMAC_SHA_256
   }
   settings: LegalEntitySettings = {
      occasionalAmount: 150,
      occasionalCurrency: 'EUR',
      occasionalCount: 1,
      occasionalPeriod: 1,
      domain: '',
      emailTemplateParams: {
         apiKey: '',
         appStoreAppUrl: '',
         googlePlayAppUrl: '',
         logoUrl: '',
         sender: '',
         senderName: '',
         supportEmail: '',
      }
   }
}

export interface SumSubSettings {
   clientId: string;
   secret: string;
   token: string;
   algorithm: Algorithm
}

export interface EmailTemplateParams {
   apiKey: string,
   appStoreAppUrl: string,
   googlePlayAppUrl: string,
   logoUrl: string,
   sender: string,
   senderName: string,
   supportEmail: string,
}

export interface LegalEntitySettings {
   occasionalAmount: number,
   occasionalCurrency: string,
   occasionalCount: number,
   occasionalPeriod: number,
   domain: string,
   emailTemplateParams: EmailTemplateParams
}

export interface LegalEntityOne {
   id: string | null;
   name: string;
   seon: string;
   sumSubSettings: SumSubSettings;
   createdAt?: number;
   updatedAt?: number;
   settings: LegalEntitySettings
}

export function useLegalEntityList() {
   return useFetchApi(
      async (data: any) => Api.post('/legal-entity/list', data),
      {
         items: []
      },
   )
}
export function useLegalEntityOne() {
   return useFetchApi(
      async (id: string) => Api.post('/legal-entity/one', { id }),
      { data: new LegalEntity() },
   );
}

export function useLegalEntityCreate(cb: any) {
   return useUpdateApi(
      async (data: any) => Api.post('/legal-entity/create', data),
      cb,
      `Legal Entity successfully created`
   )
}

export function useLegalEntityUpdate(cb: any) {
   return useUpdateApi(
      async (data: any) => Api.post('/legal-entity/update', data),
      cb,
      `Legal Entity successfully updated`
   )
}

export function useLegalEntityDelete(cb: any) {
   return useUpdateApi(
     async (data: any) => Api.post('/legal-entity/delete', data),
     cb,
     `Legal Entity successfully deleted`
   )
}