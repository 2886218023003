import React from 'react';

import { TableHead, TableRow, Table, TableBody, TableCell } from '@material-ui/core';

import clsx from 'clsx';

import useGlobalStyles from '../../components/Layout/useGlobalStyles';
import { IColumn } from '../../hooks/useColumns';
import DateView from '../../components/DateView';
import { ISettlement } from '../../api/settlements';
import Tablecell from '../../components/TableCell/TableCell';
import useMobileStyles from '../../hooks/useMobileStyles';
import Label from '../../components/Label';
import { getSettlementStatusType } from '../../helpers/settlementHelper';

interface IProps {
  items: ISettlement[];
  columns: IColumn[];
  onRowClick: (id?: string) => void;
}

const content: { [key: string]: (i: ISettlement, key: number) => void } = {
   id: (i, key) => <Tablecell title="Request ID" key={key}>{i.id}</Tablecell>,
   created: (i, key) => <Tablecell title="Created at" key={key}><DateView value={i.createdAt} /></Tablecell>,
   updated: (i, key) => <Tablecell title="Updated at" key={key}><DateView value={i.updatedAt} /></Tablecell>,
   status: (i, key) => <Tablecell title="Status" key={key}><Label label={i.status} type={getSettlementStatusType(i.status)} size="small" /></Tablecell>,
   customerId: (i, key) => <Tablecell title="Merchant ID" key={key}> {i.customerId}</Tablecell>,
   reqAmount: (i, key) => <Tablecell title="Req. Amount" key={key}>{`${i.requestedEurAmount} EUR`}</Tablecell>,
   settlementAmount: (i, key) => <Tablecell title="Settlement amount" key={key}>{i?.settlementCurrency ? `${i.settlementAmount} ${i.settlementCurrency}` : '-'}</Tablecell>,
   legalEntity: (i, key) => <Tablecell title="Legal Entity" key={key}>{i.legalEntity}</Tablecell>,
};

export default function SettlementsList({ items, onRowClick, columns }: IProps){
  const classes = useGlobalStyles();
  const mobileClasses = useMobileStyles();

  return(
    <Table size="small">
      <TableHead>
        <TableRow className={mobileClasses.tableHead}>
          {columns.map((c) => c.component || <TableCell key={c.id}>{c.name}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((i) => (
          <TableRow 
            key={i.id} 
            className={clsx(mobileClasses.row, classes.pointer, mobileClasses.lastChild)}
            onClick={() => onRowClick(i.id.toString())} 
            hover
          >
            {columns.map((c, index) => content[c.id](i, index))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}