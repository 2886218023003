import React, { useState, useEffect } from 'react';

import clsx from 'clsx';

import { NavLink } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';

import makeStyles from '@material-ui/core/styles/makeStyles';
import blue from '@material-ui/core/colors/blue';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExitToApp from '@material-ui/icons/ExitToApp';
import PopupsProvider from './Popups';
import Timer from './Timer';
import { MainMenu } from './MainMenu';
import { useLogout } from '../../api/admin';
import { logOutAction } from '../../store/actions/common';
import { useCountriesList } from '../../api/kyc';
import GlobalCountrySelect from '../../components/GlobalCountrySelect';
import ListIcon from '@material-ui/icons/List';
import { useBreakpoints } from '../../hooks/useBreakpoints ';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100vh'
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  shadow: {
    boxShadow: `0px 2px 0px ${theme.palette.warning.main}`
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'none',
    background: blue[200],
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    color: theme.palette.getContrastText(blue[200])
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
  menuButton: {
    marginRight: 36,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  menuMobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginRight: 5,
    },
    [theme.breakpoints.down('xs')]: {
      flexGrow: 1,
    },
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
    }
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
    [theme.breakpoints.down('sm')]: {
      width: 0,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    minWidth: '100%'
  },
  fixedHeight: {
    height: 240,
  },
  env: {
    height: 18,
    fontSize: 10,
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    padding: '0 5px',
    borderRadius: 10,
    background: theme.palette.divider,
    '&.dev': {
      background: theme.palette.warning.main
    },
    '&.prod': {
      background: theme.palette.success.main
    }
  },
  envHeader: {
    alignSelf: 'flex-end',
    textTransform: 'uppercase',
    fontSize: 10,
    background: theme.palette.warning.main,
    fontWeight: 700,
    padding: '5px',
    letterSpacing: '0.8px',
    margin: '0 auto'
  },
  account: {
    display: 'flex',
    alignItems: 'center',
    '& .name': {
      fontSize: 14
    }
  }
}));

interface ILayoutProps {
  children: any;
  title: string;
}

export default function Layout({ children, title }: ILayoutProps) {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();
  const isResolutionSevenHundred = useBreakpoints(700);
  const { user } = useSelector((state: any) => state.common);
  const [{ items: countries }, loading, loadCountries] = useCountriesList();
  let testEnvName: string | undefined;
  if (process.env.REACT_APP_WIDGET_ENV === 'dev' && !isResolutionSevenHundred) {
    testEnvName = 'Dev';
  }
  if (process.env.REACT_APP_WIDGET_ENV === 'stage' && !isResolutionSevenHundred) {
    testEnvName = 'Staging';
  }



  useEffect(() => {
    loadCountries();
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [logout] = useLogout((res: any) => {
    if (res.success) {
      dispatch(logOutAction());
    }
  });


  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={clsx(classes.toolbar, testEnvName && classes.shadow)}>
          <div className={classes.menuMobile}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer mobile"
              onClick={() => setOpen(!open)}
            >
              <ListIcon />
            </IconButton>
          </div>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" noWrap className={clsx(!testEnvName && classes.title)}>
            {title}
          </Typography>
          {testEnvName &&
            (<div className={classes.envHeader}>
              {`Environment: ${testEnvName}`}
            </div>)}
          <GlobalCountrySelect />
          <div className={classes.account}>
            <Link className="name" component={NavLink} to="/profile/settings" color="inherit">
              {user.firstName ? `${user.firstName} ${user.lastName}` : user.username}
            </Link>
            <IconButton color="inherit" onClick={logout}>
              <ExitToApp />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <div>
            <Typography variant="h6">UTC Time</Typography>
            <Timer />
          </div>
          <div className={clsx(classes.env, process.env.REACT_APP_WIDGET_ENV)}>{process.env.REACT_APP_WIDGET_ENV || 'Local'}</div>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <MainMenu onClose={handleDrawerClose}/>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={false} className={classes.container}>
          {children}
          <PopupsProvider />
        </Container>
      </main>
    </div>
  );
}
