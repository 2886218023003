import clsx from 'clsx';

import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';

import { IColumn } from '../../../hooks/useColumns';
import useMobileStyles from '../../../hooks/useMobileStyles';
import { IBookkeepingBalanceItem } from '../../../api/bookkeeper';
import Tablecell from '../../../components/TableCell/TableCell';
import useGlobalStyles from '../../../components/Layout/useGlobalStyles';

interface IBookkeeperBalanceListProps {
  columns: IColumn[];
  items: IBookkeepingBalanceItem[];
  onRowClick: (id?: string) => void;
}

const content: { [key: string]: (i: IBookkeepingBalanceItem, key: number) => React.ReactNode } = {
  id: (i, key) => <Tablecell title="ID" key={key}>{i.id}</Tablecell>,
  account: (i, key) => <Tablecell title="Account" key={key}>{i.account}</Tablecell>,
  subAccount: (i, key) => <Tablecell title="Sub Account" key={key}>{i.subAccount}</Tablecell>,
  balance: (i, key) => <Tablecell title="Balance" key={key}>{i.balance}</Tablecell>,
  balanceEur: (i, key) => <Tablecell title="Balance EUR" key={key}>{i.balanceEur}</Tablecell>,
  currency: (i, key) => <Tablecell title="Currency" key={key}>{i.currency}</Tablecell>,
  legalEntity: (i, key) => <Tablecell title="Legal Entity" key={key}>{i.tags.legalEntity}</Tablecell>,
};

export default function BookkeeperBalanceList({ items, onRowClick, columns }: IBookkeeperBalanceListProps) {
  const classes = useGlobalStyles();
  const mobileClasses = useMobileStyles();
  
  return (
    <Table size="small">
      <TableHead>
        <TableRow className={mobileClasses.tableHead}>
          {columns.map((column) => column.component || <TableCell key={column.id}>{column.name}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((item) => (
          <TableRow 
            hover
            key={item.id} 
            onClick={() => onRowClick(item.id.toString())} 
            className={clsx(mobileClasses.row, classes.pointer, mobileClasses.lastChild)}
          >
            {columns.map((column, index) => content[column.id](item, index))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}