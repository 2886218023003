import Api, { useFetchApi, useUpdateApi } from './index';

export enum AccountName {
  SKRILL = 'SKRILL',
  FINARO = 'FINARO',
  LA_FINTECA = 'LA_FINTECA',
  MPAY = 'MPAY',
  EUPAGO = 'EUPAGO',
  CONNECTUM = 'CONNECTUM',
  NOX = 'NOX',
  VIMONI = 'VIMONI',
  PAYSAFE = 'PAYSAFE',
  CAURI_PROCESSING = 'CAURI_PROCESSING',
  FINMO = 'FINMO',
  PAYTURCA = 'PAYTURCA',
  FOURBILL = 'FOURBILL',
  SAFECHARGE = 'SAFECHARGE',
  PAYTURE = 'PAYTURE',
  CARDPAY = 'CARDPAY',
  CAURI_DIRECT = 'CAURI_DIRECT',
  IVY = 'IVY'
}

type ILegalEntityTags = {
  legalEntity: string
}

export interface IBookkeepingBalanceItem {
  id: number;
  balance: number;
  currency: string;
  subAccount: string;
  balanceEur: number;
  account: AccountName;
  tags: ILegalEntityTags;
}

interface IBookkeeperBalancePayload {
  accounts: string[],
  subAccounts: string[],
}

export function useBookkeeperBalanceList() {
  return useFetchApi(
    async (data: IBookkeeperBalancePayload) => Api.post('/bookkeeper/balances', data),
    { items: [] }
  );
}

export function useBookkeeperForceFetchBalance(cb: (res: { success: boolean }) => void) {
  return useUpdateApi(
    async () => Api.post('/bookkeeper/fetch', {}),
    cb,
    'Force fetch completed successfully'
  );
}

export function useBookkeeperReport() {
  return useFetchApi(
    async (query: any) => Api.post('bookkeeper/report', query),
    { data: {} }
  )
}
