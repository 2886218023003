import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Form } from 'react-final-form';
import { Input, Select } from '../../../../components/Form';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import useFormStyles from '../../../../hooks/useFormStyles';
import { ITXLimit, TransactionLimitPeriod, TransactionOBILimitPeriod } from '../../../../api/customers';
import { required } from '../../../../validators';
import { useBreakpoints } from '../../../../hooks/useBreakpoints ';
import clsx from 'clsx';

interface IProps {
  limit?: ITXLimit;
  id: string;
  save: (values: any)=> void;
  saving: boolean;
  isOBI?: boolean;
  isMerchant: boolean;
}

const useStyles = makeStyles((theme)=>({
  root: {},
  button: {
    maxWidth: '80px !important'
  },
  title: {
    whiteSpace: 'nowrap'
  },
  mobile: {
    gap: '15px',
  },
  mobileRow: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    },
  },
  row: {
    marginBottom: theme.spacing(3),
  }
}));

export default function TXLimitForm({ limit, id, save, saving, isOBI, isMerchant }: IProps) {
  const classes = useFormStyles();
  const localClasses = useStyles();
  const isResolutionSevenHundred = useBreakpoints(700);

  const periodOptions = Object.keys(isOBI ? TransactionOBILimitPeriod : TransactionLimitPeriod)
    .map((s) => ({ value: s, label: s }));

  return (
    <Form
      onSubmit={(values => save({ id, ...values }))}
      initialValues={limit}
      render={({ handleSubmit, invalid }) => (
        <form onSubmit={handleSubmit}>
          <div className={clsx(
            classes.row,
            isOBI && localClasses.row,
            isResolutionSevenHundred && localClasses.mobile
          )}>
            {!limit
              ? (
                <Select
                  name="period"
                  label="Period"
                  options={periodOptions}
                  validate={required}
                  margin="none"
                />
              ) : <Input name="period" label="Period" margin="none" disabled />}
            {!isOBI && <Input name="count" label="Count" margin="none" />}
            {(!isOBI || isMerchant) && <Input name="amount" label="Amount" margin="none" />}
            {limit && (!isOBI || !isMerchant) && (
              <>
                <div className={localClasses.mobileRow}>
                  <Typography variant="body2" component="div" color="textSecondary" className={localClasses.title}>
                    Actual amount
                  </Typography>
                  <Typography variant="body1" component="div">{limit.actualAmount || '0.00'}</Typography>
                </div>
                <div className={localClasses.mobileRow}>
                  <Typography variant="body2" component="div" color="textSecondary" className={localClasses.title}>
                    Actual count
                  </Typography>
                  <Typography variant="body1" component="div">{limit.actualCount || '0'}</Typography>
                </div>
              </>
            )}
            {(!isOBI || isMerchant) &&
                <Button
                    type="submit"
                    variant="contained"
                    disabled={invalid || saving}
                    size="large"
                    className={localClasses.button}
                >
                  {!limit ? 'Add' : 'Update'}
                </Button>
            }
          </div>
        </form>
      )}
    />
  );
}
