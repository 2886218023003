import { offset } from './datesHelper';
import { IApmFee } from '../api/customers';
import { IApmFutureFeesFormData } from '../views/Customers/Details/Fees/CustomerFutureFeesForm';
import { IApmFutureFeeRow } from '../views/Customers/Details/Fees/CustomerFutureFeesForm/DynamicApmFeesForm';

export const parseApmFutureFees = (values: IApmFutureFeesFormData) => {
  if (!values?.apmFee || !Array.isArray(values.apmFee)) {
    return {};
  }

  return values.apmFee.reduce((acc: Record<string, IApmFee>, item) => {
    const { apm, ...rest } = item;
    acc[apm] = rest;
    return acc;
    }, {});
};

export const getActivateDate = (scheduledTime?: number) => {
  if (!scheduledTime) {
    return new Date().valueOf() + offset;
  }

  return scheduledTime + offset;
};

export const getInitialApmFee = (apmFee?: object | Record<string, IApmFee>) => {
  if (!apmFee || (apmFee && !Object.keys(apmFee).length)) {
    return undefined;
  }

  return Object.entries(apmFee).map(([apm, value]) => {
    const { index, ...rest } = value;
    return { apm, ...rest } as IApmFutureFeeRow;
  });
};