import { useEffect } from 'react';

import { Waypoint } from 'react-waypoint';
import { useHistory, useParams } from 'react-router';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CardActions from '@material-ui/core/CardActions';

import WalletLockDetails from './Details';
import WalletLocksList from './WalletLocksList';
import WalletLocksFilter from './WalletLocksFilter';

import Loader from '../../components/Loader';
import useFilter from '../../hooks/useFilter';
import useColumns from '../../hooks/useColumns';
import EmptyList from '../../components/EmptyList';
import { getEmptyWalletLocksFilter } from '../../helpers';
import { IWalletLock, useWalletLocks } from '../../api/walletLock';
import useGlobalStyles from '../../components/Layout/useGlobalStyles';
import { WALLET_LOCK_DEFAULT_COLUMNS } from '../../constants/walletLock';

type IParams = {
  walletLockId: string
}

export default function WalletLocksPage() {
  const history = useHistory();
  const { walletLockId } = useParams<IParams>();

  const classes = useGlobalStyles();

  const [columns, ColumnsFilter] = useColumns('walletLocks', WALLET_LOCK_DEFAULT_COLUMNS);
  const [setFilter, filter] = useFilter('walletLocks', getEmptyWalletLocksFilter(), false);
  const [{ items, cursor, full: isFull, error }, isLoading, loadWalletLocks] = useWalletLocks();

  useEffect(() => {
    loadWalletLocks({ cursor: 0, filter });
  }, [filter]);

  const handleSetDetails = (id?: string) => history.push(`/wallet-locks${id ? `/${id}` : ''}`);

  return (
    <Grid container spacing={3}>
      <WalletLocksFilter filter={filter} onChange={setFilter} />
      <Grid item className={classes.table} style={{ width: '100%', minWidth: 'fit-content' }}>
        <Paper className={classes.paper}>
          <CardActions>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <ColumnsFilter />
              </Grid>
            </Grid>
          </CardActions>
          {items.length > 0 && <WalletLocksList items={items} onRowClick={handleSetDetails} columns={columns} />}
          {!items.length && <EmptyList loading={isLoading} error={error} />}
          <Loader loading={isLoading} />
          {!isLoading && !error && !isFull && <Waypoint onEnter={() => loadWalletLocks({ cursor, filter })} />}
        </Paper>
      </Grid>
      {walletLockId && (
        <WalletLockDetails 
          item={items.find((item: IWalletLock) => walletLockId === String(item.id))} 
          isLoading={isLoading}
          onClose={() => handleSetDetails()} 
        />
      )}
    </Grid>
  );
}
    