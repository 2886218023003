import React, { useEffect } from 'react';

import clsx from 'clsx';

import { Form } from 'react-final-form';

import Button from '@material-ui/core/Button';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { sortStrings } from '../PaymentProvidersList';

import { CurrencyType } from '../../../../api/currency';
import useFormStyles from '../../../../hooks/useFormStyles';
import { mustBeNumber, required } from '../../../../validators';
import { useBreakpoints } from '../../../../hooks/useBreakpoints ';
import { IKycCountry, useKycCountries } from '../../../../api/kyc';
import { useUpdatePaymentProvider } from '../../../../api/paymentProviders';
import { PROVIDER_DELAY_INTERVALS } from '../../../../constants/providerDelays';
import { LegalEntityOne, useLegalEntityList } from '../../../../api/legalEntity';
import { Checkbox, CurrencySelect, Input, ProtectionInput, Select } from '../../../../components/Form';

interface IProps {
  data: any;
  onUpdate: any;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  form: {
    marginTop: theme.spacing(2)
  },
  supported: {
    marginTop: theme.spacing(1)
  }
}));

export default function PaymentProviderDetails({ data, onUpdate }: IProps) {
  const classes = useStyles();
  const formClasses = useFormStyles();
  const [{ items: countries }, loadingCountries, loadCountries] = useKycCountries();
  const [{ items: entities }, loadingEntity, loadEntity] = useLegalEntityList();
  const theme = useTheme();
  const isResolutionSevenHundred = useBreakpoints(700);
  const [update, updating] = useUpdatePaymentProvider((res: any) => {
    if (res.success) {
      onUpdate()
    }
  })

  useEffect(() => {
    loadEntity()
    loadCountries();
  }, [])
  
  function sortCountries(data:any){
    const sorted = data.map((code:any) => countries.find((c:IKycCountry) => c.code === code)).filter((c:any )=> c !== undefined).sort((a:any, b:any) => a.name.localeCompare(b.name)).map((c:IKycCountry) => c.code)
    return sorted
  }

  return (
    data && Object.keys(data).length
      ? (
        <>
          <Typography variant="h6" color="primary">{data.name}</Typography>
          <Form
            onSubmit={update}
            initialValues={{ supportedCurrencies: undefined, ...data,countries: sortCountries(data.countries), '2fa': ''}}
            render={({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit} className={clsx(formClasses.root, classes.form)}>
                <div className={formClasses.row}>
                  <Input
                    name="id"
                    label="ID"
                    validate={required}
                  />
                  <Input
                    name="name"
                    label="Name"
                  />
                </div>
                <div className={formClasses.row}>
                  <Input
                    name="priority"
                    label="Priority"
                    validate={mustBeNumber}
                  />
                  <Input
                    name="processingExpirationTime"
                    label="Processing expiration time"
                    validate={mustBeNumber}
                  />
                </div>
                <div className={formClasses.row}>
                  <CurrencySelect
                    name="currencies"
                    label="Currencies"
                    multi
                    margin="normal"
                    supported={values.supportedCurrencies}
                  />
                  <CurrencySelect
                    name="supportedCurrencies"
                    label="Supported currencies"
                    multi
                    margin="normal"
                    type={CurrencyType.FIAT}
                    include={data.supportedCurrencies}
                  />
                </div>
                <div className={formClasses.row}>
                  <Select
                    noEmptyOption
                    margin="normal"
                    name="processingDelay"
                    label="Processing Delay"
                    options={PROVIDER_DELAY_INTERVALS}
                    fullWidth={isResolutionSevenHundred}
                  />
                </div>
                <div className={formClasses.row}>
                  <Select
                    name="supportedLegalEntities"
                    label={`Legal Entities`}
                    options={entities.map((c: LegalEntityOne) => ({ value: c.id, label: `${c.name}` }))}
                    multi
                    loading={loadingEntity}
                    margin="normal"
                    fullWidth={isResolutionSevenHundred}
                  />
                </div>
                <div className={formClasses.row}>
                  <Select
                    name="countries"
                    label={`Countries (${data.countries?.length || 0})`}
                    options={countries.map((c: IKycCountry) => ({ value: c.code, label: `${c.name} (${c.code})` }))}
                    multi
                    loading={loadingCountries}
                    margin="normal"
                  />
                </div>
                {countries.length && (
                  <div className={formClasses.row}>
                    {data.countries?.sort(sortStrings).join(', ')}
                  </div>
                )}
                <div className={formClasses.row}>
                  <Checkbox name="enabled" label="Enabled" />
                  <Checkbox name="depositSupported" label="Deposit supported" />
                  <Checkbox name="withdrawalSupported" label="Withdrawal supported" />
                </div>
                <div className={formClasses.row}>
                  <ProtectionInput />
                  <div />
                </div>
                <div className={formClasses.actions}>
                  <Button
                    type="submit"
                    disabled={updating}
                    variant="contained"
                  >Save</Button>
                </div>
              </form>
            )}
          />
        </>
      )
      : <Typography align="center">No data to display</Typography>
  );
}
