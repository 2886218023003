import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import Label from '../../../components/Label';
import DateView from '../../../components/DateView';
import { IWalletLock } from '../../../api/walletLock';
import { DataRow } from '../../../components/DataView';
import DetailsPopup from '../../../components/Layout/DetailsPopup';

interface IWalletLockDetailsProps {
  item: IWalletLock;
  isLoading: boolean;
  onClose: () => void;
}

export default function WalletLockDetails({ item, onClose, isLoading }: IWalletLockDetailsProps) {
  const classes = useStyles();

  return (
    <DetailsPopup onClose={onClose} loading={isLoading}>
      {item?.id && (
        <>
          <Typography
            variant="h6"
            color="primary"
            className={classes.title}
          >
            Wallet Lock ID {item.id}
          </Typography>
          <DataRow label="Customer ID">{item.customerId}</DataRow>
          <DataRow label="Operation ID">{item.operationId}</DataRow>
          <DataRow label="Wallet ID">{item.walletId}</DataRow>
          <DataRow label="Unlocked">
            <Label 
              size="small" 
              label={item.unlocked ? 'YES' : 'NO'}
              type={item.unlocked ? 'success' : 'danger'}
            />
          </DataRow>
          <DataRow label="Unlock At"><DateView value={item.unlockAt} /></DataRow>
          <DataRow label="Created"><DateView value={item.createdAt} /></DataRow>
          <DataRow label="Updated"><DateView value={item.updatedAt} /></DataRow>
        </>
      )}
      {!item?.id && <Typography align="center">No data to display</Typography>}
    </DetailsPopup>
  );
}

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2.5),
  },
}));