import { IReplacementsSettings } from '../../../../../api/customers';
import { IReplacementApmRow } from '../DynamicSubstitutionApmForm';

interface IFormReplacements {
  id?: string;
  apm?: IReplacementApmRow[]
}

const flattenReplacementApms = (
  apmReplacementsMap?: IReplacementsSettings['apm']
 ): IReplacementApmRow[] | undefined => {
  if (!apmReplacementsMap || !Object.keys(apmReplacementsMap).length) {
    return undefined;
  }

  return Object.entries(apmReplacementsMap).map(([originalApmId, replacementData]) => {
    const { apm, merchantId } = replacementData;
    return { originalApmId, replacementApmId: apm, merchantId } as IReplacementApmRow;
  });
};
 
export const convertReplacementsFromServerToForm = (
  replacements?: IReplacementsSettings
) => {
  return {
    id: replacements?.id,
    apm: flattenReplacementApms(replacements?.apm),
  };
};

const mapReplacementApmsFromFormToServer = (
  formReplacementApms?: IReplacementApmRow[]
) => {
  if (!formReplacementApms) {
    return {} as IReplacementsSettings['apm'];
  }

  return formReplacementApms.reduce((acc: NonNullable<IReplacementsSettings['apm']>, item) => {
    const { originalApmId, replacementApmId, merchantId } = item;

    const replacementApm = { apm: replacementApmId };

    acc[originalApmId] = merchantId ? { ...replacementApm, merchantId }: replacementApm;
    return acc;
    }, {});
};

export const convertReplacementsFromFormToServer = (formReplacements?: IFormReplacements): IReplacementsSettings => {
  return {
    id: formReplacements?.id ?? null,
    apm: mapReplacementApmsFromFormToServer(formReplacements?.apm),
  };  
};