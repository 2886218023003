import { IProviderDelayRow } from '../types';

interface IProviderOption {
  value: string;
  label: string;
}

interface IProviderOptionsParams {
  providerOptions: IProviderOption[];
  providerDelays?: IProviderDelayRow[];
  index?: number;
}

export const getInitialProviderDelays = (providerDelays?: Record<string, string>) => {
  if (!providerDelays || (!Object.keys(providerDelays).length)) {
    return undefined;
  }

  return Object.entries(providerDelays).map(([provider, delay]) => {
    return { provider, delay } as IProviderDelayRow;
  });
};

export const parseProviderDelaysFromForm = (providerDelays?: IProviderDelayRow) => {
  if (!providerDelays || !Array.isArray(providerDelays)) {
    return {};
  }

  return providerDelays.reduce((acc: Record<string, string>, item) => {
    const { provider, delay } = item;
    acc[provider] = delay;
    return acc;
    }, {});
};

export const getAvailableProviderOptions = ({
  providerOptions,
  providerDelays,
  index,
}: IProviderOptionsParams): IProviderOption[] => {
  if (!providerDelays || !Array.isArray(providerDelays)) {
    return providerOptions;
  }

  const selectedProviders = providerDelays
    .filter((_, i) => i !== index)
    .map(item => item.provider);

  return providerOptions.filter(option => !selectedProviders.includes(option.value));
};