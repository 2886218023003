import { useState } from 'react';

import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

import clsx from 'clsx';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { IFutureFee } from '../../../../../types/fees.types';
import useFormStyles from '../../../../../hooks/useFormStyles';
import { INITIAL_2FA_FORM_VALUE } from '../../../../../constants/auth';
import { getUTCTime, offset } from '../../../../../helpers/datesHelper';
import DynamicApmFeesForm, { IApmFutureFeeRow } from './DynamicApmFeesForm';
import { DateTimeField, ProtectionInput } from '../../../../../components/Form';
import { composeValidators, required, validateDate } from '../../../../../validators';
import { getActivateDate, getInitialApmFee, parseApmFutureFees } from '../../../../../helpers/futureFeesHelper';
import { 
  ICustomer, 
  useCustomerFutureFeesUpdate,
  useCustomerFutureFeesCreate,
  useCustomerFutureFeesDelete
} from '../../../../../api/customers';

const useStyles = makeStyles((theme) => ({
  emptyFeesBlock: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    justifyContent: 'space-between',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
      gap: '10px'
    },
  },
  actions: {
    marginBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between'
  },
  form: {
    padding: theme.spacing(1)
  },
  protection: {
    marginRight: '0 !important',
  },
  deleteButton: {
    maxWidth: 150,
  },
  title: {
    marginBottom: theme.spacing(2.5),
  },
}));

const getInitialValues = (futureFees: IFutureFee | undefined) => {
  return { 
    ...INITIAL_2FA_FORM_VALUE,
    activateAt: getActivateDate(futureFees?.activatedAt),
    apmFee: getInitialApmFee(futureFees?.fees?.apmFee)
  };
};

interface ICustomerFeesFormProps {
  customer: ICustomer
  futureFees: IFutureFee | undefined
  loadFutureFees: (customerId: string) => void
}

export interface IApmFutureFeesFormData {
  '2fa': string
  activateAt: Date
  apmFee?: object | IApmFutureFeeRow[]
}

export default function CustomerFutureFeesForm({ customer, futureFees, loadFutureFees }: ICustomerFeesFormProps) {

  const classes = useStyles();
  const formClasses = useFormStyles();

  const [showNewFeeForm, setShowNewFeeForm] = useState(false);

  const [create, creating] = useCustomerFutureFeesCreate((res: { success: boolean }) => {
    if (res.success) {
      loadFutureFees(customer.id);
    }
  });

  const [update, updating] = useCustomerFutureFeesUpdate((res: { success: boolean }) => {
    if (res.success) {
      loadFutureFees(customer.id);
    }
  });

  const [deleteFutureFees, deleting] = useCustomerFutureFeesDelete((res: { success: boolean }) => {
    if (res.success) {
      loadFutureFees(customer.id);
    }
  });

  const onSubmit = (values: IApmFutureFeesFormData) => {
    const parsedApmFutureFees = parseApmFutureFees(values);

    const payload = {
      '2fa': values['2fa'],
      customerId: customer.id,
      fees: {
        activateAt: values.activateAt.valueOf() - offset,
        apmFee: parsedApmFutureFees
      }
    };

    if (!futureFees) {
      create(payload);
    } else {
      update({ ...payload, id: futureFees.id });
    }
  };

  return (
    <>
      <div className={formClasses.row}>
        <Typography variant="body1" color="primary">Scheduled future fees</Typography>
      </div>

      {!futureFees && !showNewFeeForm && (
        <div className={classes.emptyFeesBlock}>
          No scheduled future fees
          <div>
            <Button
              type="submit"
              variant="contained"
              onClick={() => setShowNewFeeForm(true)}
            >
              Add Future Fees
            </Button>
          </div>
        </div>
      )}

      {(showNewFeeForm || futureFees) &&
        <Form
          onSubmit={(values) => onSubmit(values)}
          initialValues={getInitialValues(futureFees)}
          mutators={{
            ...arrayMutators,
          }}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit} className={clsx(formClasses.root, classes.form)}>
              {futureFees?.adminEmail && (
                <div className={formClasses.row}>
                  Admin email: {futureFees.adminEmail}
                </div>
              )}

              <div className={formClasses.row}>
                <DateTimeField 
                  name="activateAt"
                  label="Activate at"
                  min={getUTCTime(new Date())}
                  validate={composeValidators(required, validateDate)}
                />
              </div>

              <div className={formClasses.row}>
                <Typography variant="body1" color="primary">
                  Apm Future Fees
                </Typography>
              </div>

              <DynamicApmFeesForm apmFee={values?.apmFee} />

              <div className={formClasses.row}>
                <ProtectionInput className={classes.protection} />
              </div>

              <div className={classes.actions}>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={creating || updating || deleting || !values?.apmFee}
                >
                  Save fees
                </Button>
              </div>
            </form>
          )}
        />
      }

      {futureFees && (
        <div className={formClasses.row}>
          <Typography variant="h6" className={classes.title} color="primary">Delete Future Fees</Typography>
          <Form
            onSubmit={(values) => deleteFutureFees(values)}
            initialValues={{ ...INITIAL_2FA_FORM_VALUE, id: futureFees?.id }}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className={formClasses.row}>
                  <ProtectionInput margin="none" />
                  <Button
                    size="large"
                    type="submit"
                    color="secondary"
                    variant="contained"
                    className={classes.deleteButton}
                    disabled={creating || updating || deleting}
                  >
                    Delete Fee
                  </Button>
                </div>
              </form>
            )}
          />
        </div>
      )}
    </>
  );
}
