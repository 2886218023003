import { FieldArray } from 'react-final-form-arrays';

import clsx from 'clsx';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';

import { Input } from '../../../../../components/Form';
import useFormStyles from '../../../../../hooks/useFormStyles';
import ApmSelect from '../../../../../components/Form/ApmSelect';
import { mustBeNumber, required } from '../../../../../validators';
import { DEFAULT_APM_REPLACEMENT } from '../../../../../constants/apmReplacements';

export interface IReplacementApmRow {
  originalApmId: string;
  merchantId?: number;
  replacementApmId: string;
}

interface IDynamicSubstitutionApmFormProps {
  replacementApms?: IReplacementApmRow[]
}

function DynamicSubstitutionApmForm({ replacementApms }: IDynamicSubstitutionApmFormProps) {

  const classes = useStyles();
  const formClasses = useFormStyles();

  return (
    <div key="replacements.apm">
      <FieldArray name="replacements.apm">
        {({ fields }) => (
          <div>
            {fields.map((name, index) => (
              <div key={name}>
                <div className={clsx(formClasses.row, classes.dynamicRow)}>
                  <ApmSelect
                    margin="normal"
                    validate={required}
                    label="Сurrent Apm"
                    name={`${name}.originalApmId`}
                    exclude={
                      replacementApms && Array.isArray(replacementApms)
                        ? replacementApms
                            .filter((_, i) => i !== index)
                            .map((item) => item.originalApmId)
                        : []
                    }
                  />
                  <ApmSelect
                    margin="normal"
                    validate={required}
                    label="Replacement Apm"
                    name={`${name}.replacementApmId`}
                  />
                  <Input
                    type="search"
                    margin="normal"
                    label="Merchant ID"
                    autoComplete="xyz123"
                    validate={mustBeNumber}
                    name={`${name}.merchantId`}
                  />
                  <div className={classes.wrapperRemoveButton}>
                    <Button
                      type="button"
                      variant="contained"
                      color="secondary"
                      onClick={() => fields.remove(index)}
                      className={classes.button}
                    >
                      Remove
                    </Button>
                  </div>
                </div>
              </div>
            ))}
            <div className={formClasses.row}>
              <div className={classes.wrapperAddButton}>
                <Button
                  type="button"
                  variant="contained"
                  onClick={() => fields.push(DEFAULT_APM_REPLACEMENT)}
                >
                  Add APM Replacement
                </Button>
              </div>
            </div>
          </div>
        )}
      </FieldArray>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  wrapperAddButton: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  wrapperRemoveButton: {
    paddingTop: theme.spacing(2)
  },
  dynamicRow: {
    '& .MuiAutocomplete-inputRoot': {
      paddingTop: '2px !important',
      paddingBottom: '2px !important',
    }
  },
  button: {
    height: 40,
  }
}));

export default DynamicSubstitutionApmForm;