import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Layout from '../Layout';
import { AbilityContext } from '../../acl/can';
import { Actions, Subjects } from '../../acl/ability';

interface IRouteProps {
  component: any;
  title: string;
  exact?: boolean;
  path: string;
  subject?: Subjects;
  action?: Actions;
}

export default function RouteWithLayout(props: IRouteProps) {
  const { component: Component, title, subject, action = 'read', ...rest } = props;
  const ability = useContext(AbilityContext);

  if (ability.rules.length === 0) {
    return <div>Loading...</div>;
  }

  const actualSubject = subject || 'all';

  if (ability.cannot(action, actualSubject)) {
    return <Redirect to="/orders" />;
  }

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout title={title}>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
}
