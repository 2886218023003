import React, { useEffect } from 'react';
import { Form } from 'react-final-form';

import Button from '@material-ui/core/Button';

import { Checkbox, Input, ProtectionInput } from '../../components/Form';
import Loader from '../../components/Loader';
import useFormStyles from '../../hooks/useFormStyles';
import { useGetGlobalSettings, useSetGlobalSettings} from '../../api/globalSettings';

export default function MerchantSettingsTab() {
  const formClasses = useFormStyles();
  const [{ data: request }, loadingGlobalSettings, loadGlobalSettings] = useGetGlobalSettings();

  const [setGlobalSettings, saving] = useSetGlobalSettings((res: { success: boolean }) => {
    if (res.success) {
      loadGlobalSettings();
    }
  });

  useEffect(() => {
    loadGlobalSettings();
  }, []);

    return (
      <Loader loading={loadingGlobalSettings} width={500}>
        <Form
          onSubmit={(values) => {
            setGlobalSettings({ ...values,
              excludedMerchantIds: !values?.excludedMerchantIds ? [] : String(values?.excludedMerchantIds).split(','),
            });
          }}
          initialValues={{
            passCoRToProvider: request?.passCoRToProvider,
            excludedMerchantIds: !request?.excludedMerchantIds ? [] : String(request?.excludedMerchantIds).split(','),
            '2fa': ''
          }}
          render={({ handleSubmit, invalid }) => (
            <form onSubmit={handleSubmit}>
              <div className={formClasses.row}>
                <Checkbox name="passCoRToProvider" label="Enable passing CoR to provider" />
              </div>

              <div className={formClasses.row}>
                <Input type="text" name="excludedMerchantIds" margin="none" label="Excluded merchants" />
              </div>

              <div className={formClasses.row}>
                <ProtectionInput />
              </div>

              <div className={formClasses.actions}>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  disabled={invalid || saving}
                >
                  Save
                </Button>
              </div>
            </form>
          )}
        />
      </Loader>
    );
}