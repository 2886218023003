import clsx from 'clsx';

import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';

import { IColumn } from '../../hooks/useColumns';
import DateView from '../../components/DateView';
import { IWalletLock } from '../../api/walletLock';
import BoolLabel from '../../components/Label/BoolLabel';
import useMobileStyles from '../../hooks/useMobileStyles';
import Tablecell from '../../components/TableCell/TableCell';
import useGlobalStyles from '../../components/Layout/useGlobalStyles';

interface IWalletLocksListProps {
  columns: IColumn[];
  items: IWalletLock[];
  onRowClick: (id?: string) => void;
}

const content: { [key: string]: (i: IWalletLock, key: number) => React.ReactNode } = {
  id: (i, key) => <Tablecell title="ID" key={key}>{i.id}</Tablecell>,
  customerId: (i, key) => <Tablecell title="Customer ID" key={key}>{i.customerId}</Tablecell>,
  operationId: (i, key) => <Tablecell title="Operation ID" key={key}>{i.operationId}</Tablecell>,
  walletId: (i, key) => <Tablecell title="Wallet ID" key={key}>{i.walletId}</Tablecell>,
  unlocked: (i, key) => <Tablecell title="Unlocked" key={key}><BoolLabel value={i.unlocked} size="small" /></Tablecell>,
  unlockAt: (i, k) => <Tablecell key={k} title="Unlock At"><DateView value={i.unlockAt} /></Tablecell>,
  created: (i, key) => <Tablecell title="Created at" key={key}><DateView value={i.createdAt} /></Tablecell>,
  updated: (i, key) => <Tablecell title="Updated at" key={key}><DateView value={i.updatedAt} /></Tablecell>,
};

export default function WalletLocksList({ items, onRowClick, columns }: IWalletLocksListProps) {
  const classes = useGlobalStyles();
  const mobileClasses = useMobileStyles();
  
  return (
    <Table size="small">
      <TableHead>
        <TableRow className={mobileClasses.tableHead}>
          {columns.map((column) => column.component || <TableCell key={column.id}>{column.name}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((item) => (
          <TableRow 
            hover
            key={item.id} 
            onClick={() => onRowClick(item.id.toString())} 
            className={clsx(mobileClasses.row, classes.pointer, mobileClasses.lastChild)}
          >
            {columns.map((column, index) => content[column.id](item, index))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}