import { IColumn } from '../hooks/useColumns';

export const BOOKKEEPING_DEFAULT_COLUMNS: IColumn[] = [
  { id: 'id', name: 'ID', active: true },
  { id: 'account', name: 'Account', active: true },
  { id: 'subAccount', name: 'Sub Account', active: true },
  { id: 'balance', name: 'Balance', active: true },
  { id: 'balanceEur', name: 'Balance EUR', active: true },
  { id: 'currency', name: 'Currency', active: true },
  { id: 'legalEntity', name: 'Legal Entity', active: true },
];