import React, { useEffect } from 'react';

import { Form } from 'react-final-form';

import { Button, makeStyles, Typography } from '@material-ui/core';

import useFormStyles from '../../../hooks/useFormStyles';
import { useMerchantNamingList } from '../../../api/customers';
import SelectField from '../../../components/Form/SelectField';
import {Input, ProtectionInput, Select} from '../../../components/Form';
import DetailsPopup from '../../../components/Layout/DetailsPopup';
import { getReportFormInitialValues } from '../../../helpers/obiReports';
import { composeValidators, isEmail, minValue, mustBeNumber, required } from '../../../validators';
import { IOBIReport, useOBIReportCreate, useOBIReportDelete, useOBIReportUpdate } from '../../../api/reports';
import {useBreakpoints} from "../../../hooks/useBreakpoints ";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2.5),
  },
  buttonWrapper: {
    [theme.breakpoints.down(700)]: {
      paddingTop: theme.spacing(2),
    }
  }
}));

interface IOBIReportDetails {
  report: IOBIReport
  onClose: () => void
  updateList: () => void
}

export default function OBIReportDetails({ onClose, updateList, report }: IOBIReportDetails) {
  const formClasses = useFormStyles();
  const classes = useStyles();

  const [
    { 
      data: items,
    }, 
    loadingMerchantNaming, 
    loadMerchantNaming,
  ] = useMerchantNamingList();

  const isResolutionSevenHundred = useBreakpoints(700);

  useEffect(() => {
    if (!report?.id) {
      loadMerchantNaming('');
    }
   }, [report?.id]);

  const [create, creating] = useOBIReportCreate((res: any) => {
    if (res.success) {
      updateList();
    }
  });
  
  const [update, updating] = useOBIReportUpdate((res: any) => {
    if (res.success) {
      updateList();
    }
  });

  const [deleteReport, deleting] = useOBIReportDelete((res: any) => {
    if (res.success) {
      onClose();
      updateList();
    }
  });

  return (
    <DetailsPopup onClose={onClose} loading={loadingMerchantNaming || updating || creating}>
      <Typography variant="h6" className={classes.title} color="primary">
        {report?.id ? `Update report id ${report.id}` : 'Email notifications OBI'}
      </Typography>
      <Form
        onSubmit={(values) => {
          if (!report?.id) {
            return create(values);
          }

          return update({
            ...values, 
            executedOrdersSum: values?.executedOrdersSum ?? 0,
            executedOrdersCount: values?.executedOrdersCount ?? 0
          });
        }}
        initialValues={getReportFormInitialValues(report)}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
              {report?.id &&
                <>
                  <div className={formClasses.row}>
                    <Input
                      disabled
                      name="id"
                      label="ID"
                      type="search"
                      autoComplete="xyz123"
                    />
                    <Select
                        name="enabled"
                        label="Enabled"
                        options={[{label: 'Yes', value: true}, {label: 'No', value: false}]}
                        margin="normal"
                        validate={required}
                        noEmptyOption
                        fullWidth={isResolutionSevenHundred}
                    />
                  </div>
                  <div className={formClasses.row}>
                    <Input
                      disabled
                      name="businessName"
                      label="Business Name"
                      type="search"
                      autoComplete="xyz123"
                    />
                  </div>
                </>}
              {!report?.id &&
                <>
                  <div className={formClasses.row}>
                    <SelectField
                      fullWidth
                      name="businessName"
                      noEmptyOption
                      validate={composeValidators(required)}
                      label="Business Name"
                      options={items?.businessNames.map((item: string)=>({ label: item, value: item }))}
                    />
                    <Select
                        name="enabled"
                        label="Enabled"
                        options={[{ label: 'Yes', value: true }, { label: 'No', value: false }]}
                        margin="none"
                        validate={required}
                        noEmptyOption
                        fullWidth={isResolutionSevenHundred}
                    />
                  </div>
                </>}
              <div className={formClasses.row}>
                <Input
                  type="number"
                  validate={composeValidators(required, mustBeNumber, (v: any) => minValue(v, 0))}
                  autoComplete="123"
                  name="executedOrdersSum"
                  label="Executed orders amount"
                />
                <Input
                  type="number"
                  validate={composeValidators(required, mustBeNumber, (v: any) => minValue(v, 0))}
                  autoComplete="123"
                  name="executedOrdersCount"
                  label="Executed orders count"
                />
              </div>
              <div className={formClasses.row}>
                <Input
                  label="Email"
                  name="email"
                  validate={composeValidators(required, isEmail)}
                />
              </div>
              <div className={formClasses.row}>
                <ProtectionInput />
              </div>
              <div className={formClasses.actions}>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={updating || creating || deleting}
                >
                  Save
                </Button>
              </div>
          </form>
        )}
      />
      {report?.id && 
        <>
          <Typography 
            variant="h6" 
            className={classes.title} 
            color="primary"
          >
            {`Delete report id ${report.id}`}
          </Typography>
          <Form
            onSubmit={(values) => deleteReport({ id: report.id, '2fa': values['2fa'] })}
            initialValues={{ '2fa': '' }}
            render={({ handleSubmit, invalid }) => (
              <form onSubmit={handleSubmit}>
                <div className={formClasses.row}>
                  <ProtectionInput margin="none" />
                  <div className={classes.buttonWrapper}>
                    <Button
                      type="submit"
                      size="large"
                      disabled={deleting || updating || invalid}
                      variant="contained"
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              </form>
            )}
          />
      </>}
    </DetailsPopup>
  );
}
