import { FieldArray } from 'react-final-form-arrays';

import clsx from 'clsx';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';

import { required } from '../../../../../validators';
import { IProviderDelayRow } from '../../../../../types';
import useFormStyles from '../../../../../hooks/useFormStyles';
import SelectField from '../../../../../components/Form/SelectField';
import { IPaymentProvider } from '../../../../../api/paymentProviders';
import { getAvailableProviderOptions } from '../../../../../helpers/providerDelaysHelper';
import { DEFAULT_PROVIDER_DELAY, PROVIDER_DELAY_INTERVALS } from '../../../../../constants/providerDelays';

interface IDynamicProviderDelaysFormProps {
  providerList: IPaymentProvider[]
  providerDelays?: IProviderDelayRow[]
}

function DynamicProviderDelaysForm({ providerList, providerDelays }: IDynamicProviderDelaysFormProps) {

  const classes = useStyles();
  const formClasses = useFormStyles();

  const mappedProviderList = providerList.map(provider => ({ value: provider.id, label: provider.name }));
  
  return (
    <div key="providerDelays">
      <FieldArray name="providerDelays">
        {({ fields }) => (
          <div>
            {fields.map((name, index) => (
              <div key={name}>
                <div className={clsx(formClasses.row, classes.dynamicRow)}>
                  <SelectField
                    fullWidth 
                    noEmptyOption
                    margin="normal"
                    label="Provider"
                    validate={required}
                    name={`${name}.provider`}
                    options={getAvailableProviderOptions({
                      providerOptions: mappedProviderList,
                      providerDelays,
                      index,
                    })}
                  />
                  <SelectField
                    fullWidth 
                    label="Delay"
                    noEmptyOption
                    margin="normal"
                    validate={required}
                    name={`${name}.delay`}
                    options={PROVIDER_DELAY_INTERVALS} 
                  /> 
                  <div className={classes.wrapperRemoveButton}>
                    <Button
                      type="button"
                      variant="contained"
                      color="secondary"
                      onClick={() => fields.remove(index)}
                      className={classes.button}
                    >
                      Remove
                    </Button>
                  </div>
                </div>
              </div>
            ))}
            <div className={formClasses.row}>
              <div className={classes.wrapperAddButton}>
                <Button
                  type="button"
                  variant="contained"
                  onClick={() => fields.push(DEFAULT_PROVIDER_DELAY)}
                >
                  Add provider delay
                </Button>
              </div>
            </div>
          </div>
        )}
      </FieldArray>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  wrapperAddButton: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  wrapperRemoveButton: {
    paddingTop: theme.spacing(2)
  },
  dynamicRow: {
    '& .MuiAutocomplete-inputRoot': {
      paddingTop: '2px !important',
      paddingBottom: '2px !important',
    }
  },
  button: {
    height: 40,
  }
}));

export default DynamicProviderDelaysForm;