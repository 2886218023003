export const DEFAULT_PROVIDER_DELAY = { 
  delay: '',
  provider: '',
};

export enum DelayInterval {
  PT0S = 'PT0S',
  PT1H = 'PT1H',
  PT6H = 'PT6H',
  PT12H = 'PT12H',
  PT24H = 'PT24H',
  PT48H = 'PT48H',
  PT72H = 'PT72H',
  PT96H = 'PT96H',
  PT120H = 'PT120H',
  PT144H = 'PT144H',
  PT168H = 'PT168H'
}

export const PROVIDER_DELAY_INTERVALS = [
  { label: '0 HOURS', value: DelayInterval.PT0S },
  { label: '1 HOUR', value: DelayInterval.PT1H },
  { label: '6 HOURS', value: DelayInterval.PT6H },
  { label: '12 HOURS', value: DelayInterval.PT12H },
  { label: '1 DAY', value: DelayInterval.PT24H },
  { label: '2 DAYS', value: DelayInterval.PT48H },
  { label: '3 DAYS', value: DelayInterval.PT72H },
  { label: '4 DAYS', value: DelayInterval.PT96H },
  { label: '5 DAYS', value: DelayInterval.PT120H },
  { label: '6 DAYS', value: DelayInterval.PT144H },
  { label: '7 DAYS', value: DelayInterval.PT168H },
];