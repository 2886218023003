import React, { useContext, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import DetailsPopup from '../../../components/Layout/DetailsPopup';
import TabsPanel from '../../../components/Tabs';
import OverviewTab from './OverviewTab';
import PaymentTab from './PaymentTab';
import BlockchainTxTab from './BlockchainTxTab';
import ClearingTab from './ClearingTab';
import InitDebugTab from './InitDebugTab';
import { useOrderData } from '../../../api/order';
import { usePaymentsList } from '../../../api/payment';
import OrderTypeLabel from '../../../components/Label/OrderTypeLabel';
import { AbilityContext } from '../../../acl/can';


interface IOrderDetailsProps {
  id: string;
  onClose: any;
}

export default function OrderDetails({ id, onClose }: IOrderDetailsProps) {
  const ability = useContext(AbilityContext);
  const [{ data: order }, loading, load] = useOrderData();
  const [{ items: payments, error: paymentsError }, loadingPayments, loadPayments] = usePaymentsList();

  useEffect(() => {
    load(id, ability.cannot('view', 'order-details'));
    loadPayments({ filter: { orderId: id } });
  }, [id, ability]);

  const reloadOrderDetails = () => {
    load(id, ability.cannot('view', 'order-details'));
    loadPayments({ filter: { orderId: id } });
  };


  const defaultTabs = [
    {
      title: 'Overview',
      content: (
        <OverviewTab
          order={order}
          reloadOrderDetails={reloadOrderDetails}
          payments={payments}
          onSave={() => load(id)}
        />
      )
    },
    {
      title: 'Payments',
      content: (
        <PaymentTab
          payments={payments}
          loading={loadingPayments}
          error={paymentsError}
          orderId={order?.id}
        />
      )
    },
    {
      title: 'Blockchain',
      content: <BlockchainTxTab orderId={order?.id} />
    }
  ];

  const merchantTabs = [
    {
      title: 'Clearing',
      content: <ClearingTab orderId={order?.id} />
    },
    {
      title: 'Init debug',
      content: <InitDebugTab order={order} />
    }
  ];

  const tabs = ability.can('read', 'merchant-stuff')
    ? [...defaultTabs, ...merchantTabs]
    : defaultTabs;

  return (
    <DetailsPopup onClose={onClose} loading={loading || loadingPayments}>
      {order
        ? (
          <>
            <OrderTypeLabel type={order.type} /> <Typography>Order {order.id} </Typography>
            <TabsPanel tabs={tabs} />
          </>
        ) : <Typography align="center">No data to display</Typography>}
    </DetailsPopup>
  );
}
