import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { DataRow } from '../../../../components/DataView';
import { IBookkeepingBalanceItem } from '../../../../api/bookkeeper';
import DetailsPopup from '../../../../components/Layout/DetailsPopup';

interface IBookkeeperBalanceDetailsProps {
  item: IBookkeepingBalanceItem;
  onClose: () => void;
  isLoading: boolean;
}

export default function BookkeeperBalanceDetails({ item, onClose, isLoading }: IBookkeeperBalanceDetailsProps) {
  const classes = useStyles();

  return (
    <DetailsPopup onClose={onClose} loading={isLoading}>
      {item && (
        <>
          <Typography
            variant="h6"
            color="primary"
            className={classes.title}
          >
            Details for ID {item.id}
          </Typography>
          <DataRow label="Account">{item.account}</DataRow>
          <DataRow label="Sub Account">{item.subAccount}</DataRow>
          <DataRow label="Balance">{item.balance}</DataRow>
          <DataRow label="Balance EUR">{item.balanceEur}</DataRow>
          <DataRow label="Currency">{item.currency}</DataRow>
          <DataRow label="Legal Entity">{item.tags.legalEntity}</DataRow>
        </>
      )}
      {!item && <Typography align="center">No data to display</Typography>}
    </DetailsPopup>
  );
}

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2.5),
  },
}));