import { AbilityBuilder, createMongoAbility, MongoAbility } from '@casl/ability';

export type Actions = 'manage' | 'create' | 'read' | 'update' | 'delete' | 'view';
export type Subjects = 'merchant-stuff' | 'authorities' | 'kyc' | 'customers' | 'orders' | 'payments' | 'kyc-countries' | 'acl' | 'blockchain' | 'all';

export type AppAbility = MongoAbility<[Actions, Subjects]>;

export function createAbility(user?: any): AppAbility {
	const { can, build } = new AbilityBuilder<AppAbility>(createMongoAbility);

	if (user?.authorities?.includes('ROLE_ADMIN_AUDIT')) {
		can('read', 'kyc');
		can('read', 'customers');
		can('read', 'orders');
		can('read', 'payments');
		can('read', 'kyc-countries');
		can('read', 'acl');
	} else if (user?.authorities?.includes('ROLE_ADMIN')) {
		can('manage', 'all');
	}

	return build();
}

export default createAbility();
