import { IColumn } from '../hooks/useColumns';

export const WALLET_LOCK_DEFAULT_COLUMNS: IColumn[] = [
  { id: 'id', name: 'ID', active: true },
  { id: 'customerId', name: 'Customer ID', active: true },
  { id: 'operationId', name: 'Operation ID', active: true },
  { id: 'walletId', name: 'Wallet ID', active: true },
  { id: 'unlocked', name: 'Unlocked', active: true },
  { id: 'unlockAt', name: 'Unlock At', active: true },
  { id: 'created', name: 'Created at', active: true },
  { id: 'updated', name: 'Updated at', active: true },
];