import Api, { useFetchApi, useUpdateApi } from './index';

interface IGlobalSettings {
  excludedMerchants: number[] | string[];
  passCoRToProvider: boolean;
  '2fa': string;
}

export function useGetGlobalSettings() {
  return useFetchApi(
    async () => Api.post('/global/settings/get', {}),
    { data: false }
  );
}

export function useSetGlobalSettings(cb: any) {
  return useUpdateApi(
    async (data: IGlobalSettings) => Api.post('/global/settings/set', data),
    cb,
    'Global settings updated'
  );
}