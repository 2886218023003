import Api, { useFetchApi } from './index';

export interface IWalletLock {
  id: string;
  customerId: number;
  operationId: number;
  walletId: number;
  unlockAt: number;
  unlocked: boolean;
  createdAt: number;
  updatedAt: number;
}

interface IWalletLocksPayloadFilter {
  customerId?: number;
  walletId?: number;
  unlocked?: boolean;
}

interface IWalletLocksPayload {
  filter: IWalletLocksPayloadFilter
  cursor: number
}

export function useWalletLocks() {
  return useFetchApi(
    async (data: IWalletLocksPayload) => Api.post('/customer/wallet/locks', { ...data, limit: 50 }),
    { items: [] }
  );
}