import React, { useContext, useEffect, useRef } from 'react';

import { useHistory } from 'react-router';

import { Waypoint } from 'react-waypoint';

import { useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CardActions from '@material-ui/core/CardActions';
import TableCell from '@material-ui/core/TableCell';
import CustomersList from './CustomersList';
import EmptyList from '../../components/EmptyList';
import Loader from '../../components/Loader';
import useGlobalStyles from '../../components/Layout/useGlobalStyles';
import { useCustomersList } from '../../api/customers';
import useColumns, { IColumn } from '../../hooks/useColumns';
import CustomersFilter, { ICustomerFilter } from './CustomersFilter';
import useFilter from '../../hooks/useFilter';
import FilterWrapper, { ICollapseHandle } from '../../components/FilterWrapper/FilterWrapper';
import { сlearBusinessFields } from '../../helpers';
import { AbilityContext } from '../../acl/can';

const baseColumns: IColumn[] = [
  { id: 'id', name: 'ID', active: true, component: <TableCell align="center" key="id">ID</TableCell> },
  { id: 'contact', name: 'Email/Phone', active: true },
  { id: 'level', name: 'KYC Level', active: true },
  { id: 'confirmed', name: 'Confirmed', active: true },
  { id: 'kyc', name: 'KYC started', active: true },
  { id: 'verified', name: 'KYC passed ', active: true },
  { id: 'created', name: 'Created at', active: true },
];

const merchantColumns: IColumn[] = [
  { id: 'legalEntity', name: 'Legal Entity', active: true },
  { id: 'businessName', name: 'Business Name', active: true },
  { id: 'legalName', name: 'Legal Name', active: true },
  { id: 'legalMerchantName', name: 'Legal Merchant Name', active: true },
  { id: 'merchantName', name: 'Merchant Name', active: true },
  { id: 'accountType', name: 'Account type', active: true },
  { id: 'ccwEnabled', name: 'CCW enabled', active: true },
];

type IMerchantFilterValue = string | null | undefined | string[] | []

const parseMerchantFilter = (value: IMerchantFilterValue) => {
  if (!value) {
    return [];
  }

  if (typeof value === 'string') {
    return [value];
  }

  if (Array.isArray(value)) {
    if (value.length) {
      return value;
    }

  return [];
  }
};

export default function CustomersPage() {
  const classes = useGlobalStyles();
  const globalCountry = useSelector((store:any) => store.appData.countries.globalCountry);
  const ability = useContext(AbilityContext);
  const defaultColumns = ability.can('read', 'merchant-stuff')
    ? [...baseColumns, ...merchantColumns]
    : baseColumns;

  const emptyFilter = () => ({
    email: null,
    customerId: null,
    phone: null,
    role: undefined,
    level: undefined,
    country: globalCountry || null,
    legalEntities: [],
    businessNames: [],
    legalNames: [],
    legalMerchantNames: [],
    merchantNames: [],
    accountTypes: [],
    ccwEnabled: null,
    emptyBusinessFields: [],
  });

  const [setFilter, filter] = useFilter('customers', emptyFilter(), false);
  const [{ items, cursor, full, error }, loading, load ] = useCustomersList();
  const history = useHistory();
  const [columns, ColumnsFilter] = useColumns('customers', defaultColumns);
  const accordionRef = useRef<ICollapseHandle>(null);

  function setDetails(id?: string) {
    history.push(`/customers${id ? `/customer/${id}` : ''}`);
  }

  useEffect(() => {
    accordionRef.current?.collapse();
    if (!isNaN(Number(filter.level))) {
      filter.level = [Number(filter.level)];
    } else {
      filter.level = undefined;
    }

    filter.legalEntities = parseMerchantFilter(filter?.legalEntities);
    filter.businessNames = parseMerchantFilter(filter?.businessNames);
    filter.legalNames = parseMerchantFilter(filter?.legalNames);
    filter.legalMerchantNames = parseMerchantFilter(filter?.legalMerchantNames);
    filter.merchantNames = parseMerchantFilter(filter?.merchantNames);
    filter.accountTypes = parseMerchantFilter(filter?.accountTypes);

    const cleanedFilter  = сlearBusinessFields(filter);

    load({
      filter: cleanedFilter ,
      cursor: 0,
    });
  }, [filter]);

  useEffect(() => {
    if (filter.country !== globalCountry) {
      setFilter({
        ...filter,
        country: globalCountry,
      });
    }
  }, [globalCountry]);

  return (
    <Grid container spacing={3}>
      <FilterWrapper ref={accordionRef}>
        <CustomersFilter
          filter={filter as ICustomerFilter}
          onChange={setFilter}
        />
      </FilterWrapper>
      <Grid item className={classes.table} style={{ width: '100%', minWidth: 'fit-content' }}>
        <Paper className={classes.paper}>
          <CardActions>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <ColumnsFilter />
              </Grid>
            </Grid>
          </CardActions>
          {items.length > 0
            ? <CustomersList items={items} onRowClick={setDetails} columns={columns} />
            : <EmptyList error={error} loading={loading} />
          }
          <Loader loading={loading} />
          {!loading && !error && !full && items.length > 1 && (
            <Waypoint onEnter={() => load({ cursor, filter })} />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}
