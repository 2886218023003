import { useEffect } from 'react';

import { useHistory, useParams } from 'react-router';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';

import BookkeeperBalanceDetails from './Details';
import BalanceList from './BookkeeperBalanceList';
import BookkeeperBalanceFilter from './BookkeeperBalanceFilter';

import { 
  IBookkeepingBalanceItem,
  useBookkeeperBalanceList,
  useBookkeeperForceFetchBalance
} from '../../../api/bookkeeper';
import Loader from '../../../components/Loader';
import useFilter from '../../../hooks/useFilter';
import useColumns from '../../../hooks/useColumns';
import EmptyList from '../../../components/EmptyList';
import { getEmptyBookkeeperFilter } from '../../../helpers';
import useGlobalStyles from '../../../components/Layout/useGlobalStyles';
import { BOOKKEEPING_DEFAULT_COLUMNS } from '../../../constants/bookkeeping';

type IParams = {
  entryId: string
}

export default function BookkeeperBalancePage() {
  const history = useHistory();
  const { entryId } = useParams<IParams>();

  const classes = useGlobalStyles();

  const [columns, ColumnsFilter] = useColumns('bookkeeperBalance', BOOKKEEPING_DEFAULT_COLUMNS);
  const [setFilter, filter] = useFilter('bookkeeper_balance', getEmptyBookkeeperFilter(), false);
  const [{ items, error, full: isFull }, isLoading, loadBalance] = useBookkeeperBalanceList();
  const [forceFetchBalance, isForceFetching] = useBookkeeperForceFetchBalance((res: { success: boolean }) => {
    if (res.success) {
      setFilter(getEmptyBookkeeperFilter());
    }
  });

  useEffect(() => {
    loadBalance(filter);
  }, [filter]);

  const handleSetDetails = (id?: string) => history.push(`/bookkeeping/balance${id ? `/${id}` : ''}`);

  return (
    <Grid container spacing={3}>
      <BookkeeperBalanceFilter filter={filter} onChange={setFilter} />
      <Grid item className={classes.table} style={{ width: '100%', minWidth: 'fit-content' }}>
        <Paper className={classes.paper}>
          <CardActions>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Button 
                  size="large"
                  variant="contained"
                  disabled={isForceFetching}
                  onClick={() => forceFetchBalance()}
                >
                  Force Fetch
                </Button>
              </Grid>
              <Grid item>
                <ColumnsFilter />
              </Grid>
            </Grid>
          </CardActions>
          {items.length > 0 && <BalanceList items={items} onRowClick={handleSetDetails} columns={columns} />}
          {!items.length && <EmptyList loading={isLoading} error={error} />}
          <Loader loading={isLoading} />
        </Paper>
      </Grid>
      {entryId && (
        <BookkeeperBalanceDetails 
          item={items.find((item: IBookkeepingBalanceItem) => entryId === String(item.id))} 
          isLoading={isLoading}
          onClose={() => handleSetDetails()} 
        />
      )}
    </Grid>
  );
}
    