import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

import { Button, Typography } from '@material-ui/core';

import { IKycCountry } from '../../../../api/kyc';
import DynamicAccountsForm from './DynamicAccountsForm';
import Descriptor from '../../../../components/Descriptor';
import useFormStyles from '../../../../hooks/useFormStyles';
import { LegalEntityOne } from '../../../../api/legalEntity';
import { useBreakpoints } from '../../../../hooks/useBreakpoints ';
import { mustBeNumber, required } from '../../../../validators';
import { IDynamicProviderSettingsCreateFormProps, ISchemaField } from '../../../../types';
import { AutocompleteFieldWithCreateOption, Input, ProtectionInput, Select } from '../../../../components/Form';
import React from "react";

interface IProviderSettingsForm
  extends Pick<
    IDynamicProviderSettingsCreateFormProps,
    'countries' | 'entities' | 'loadingCountries'
  > {
  schemaId?: string
  properties: ISchemaField[]
  isDisabledSubmitButton: boolean
  initialValues?: Record<string, any>
  onSubmitForm: (values: Record<string, any>) => void
}

function ProviderSettingsForm ({ 
  entities, 
  schemaId,
  countries,
  properties,
  onSubmitForm, 
  initialValues,
  loadingCountries,
  isDisabledSubmitButton, 
  }: IProviderSettingsForm) {

  const formClasses = useFormStyles();

  const isResolutionSevenHundred = useBreakpoints(700);

  const renderField = ( key: string, field: ISchemaField) => {
    if (field.type === 'string' || field.type === 'integer') {
      return (
         <div className={formClasses.row} key={key}>
            <Descriptor group="providerSettings" title={key} description={field?.description} top={7}>
              <Input name={key} label={key} validate={field?.required && required} />
            </Descriptor>
        </div>
      );
    }

    if (field.type === 'array' && key === 'supportedLanguages') {
      return (
         <div className={formClasses.row} key={key}>
            <Select
              name={key}
              label="Supported languages"
              options={countries.map((c: IKycCountry) => ({ value: c.code, label: `${c.name} (${c.code})` }))}
              multi
              loading={loadingCountries}
              margin="none"
              fullWidth={isResolutionSevenHundred}
            />
         </div>
      );
    }

    if (field.type === 'object' && key === 'accounts') {
      return (
        <div key={key}>
          <Typography variant="body2" color="primary">{key}</Typography>
          <DynamicAccountsForm fieldName={key} />
        </div>
      );
    }

    return null;
  };

  return (
    <Form
      key={schemaId}
      onSubmit={onSubmitForm}
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className={formClasses.row}>
            <Input name="merchantIDMain" label="Merchant ID" validate={mustBeNumber} />
            <Input name="balanceFetchSchedule" label="Balance Fetch Schedule" />
          </div>
          <div className={formClasses.row}>
            <AutocompleteFieldWithCreateOption
              name="legalEntity"
              optionsList={entities.map((item: LegalEntityOne) => item.id || '')}
              label="Legal Entity"
            />
          </div>
          <Typography variant="body2" color="primary">Configuration</Typography>
          {Object.entries(properties).map(([key, field]) =>
            renderField(key, field)
          )}
          <div className={formClasses.row}>
            <ProtectionInput />
          </div>
          <div className={formClasses.actions}>
            <Button
              type="submit"
              disabled={isDisabledSubmitButton}
              variant="contained"
            >
              Save
            </Button>
          </div>
        </form>
      )}
    />
  );
}

export default ProviderSettingsForm;