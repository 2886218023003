const descriptions: any = {
  orderStatus: {
    NEW: 'New order inited by Merchant',
    ACCEPTED: 'Accepted by customer',
    PROCESSING: 'Order processing was started',
    VERIFICATION: 'Order waiting for customer KYC verification',
    SUSPICIOUS: 'Order waiting for admin action',
    EXECUTED: 'Processing was successfull, order is sent to Clearing exchange.',
    SUCCESS: 'Order successfully finished (blockchain transaction was mined)',
    ERROR: 'Order finished with error',
    EXPIRED: 'Order expired',
  },
  clearingStatus: {
    NEW: 'New clearing order initiated',
    EXECUTED: 'Clearing order created on clearing exchange.',
    SUCCESS: 'Order successfully executed on clearing exchange',
    ERROR: 'Order finished with error',
  },
  paymentStatus: {
    NEW: 'New payment',
    ACCEPTED: 'Money blocked on card',
    SUCCESS: 'Money charged or refunded',
    BLOCKED: 'Blocked by anti-fraud filter or AML officer',
  },
  blockchainTxStatus: {
    NEW: 'New transaction was initiated',
    EXECUTED: 'Transaction was created',
    SUCCESS: 'Transaction received required amount of confirmations.',
    ERROR: 'Transaction has an error. Contact tech. department',
    MANUAL: 'Blockchain transaction NOT CREATED yet. Change status to "New" and assets will be sent to provided address.'
  },
  columns: {
    Customer: 'Accepted by customer',
    'Ext. ID': 'TX ID on merchant side',
    'TX ID': 'Blockchain transaction ID',
    'Requested by': 'Customer which initiated order (Merchant)',
    'Deposit': 'Deposit',
    'Dep. Fixed': 'Dep. Fixed',
    'Withdrawal': 'Withdrawal',
    'Withdrawal Fixed': 'Withdrawal Fixed',
    'Merch. Deposit': 'Merch. Deposit',
    'Merch. Deposit Fixed': 'Merch. Deposit Fixed',
    'Rolling': 'Rolling',
    'Applied from': 'Applied from'
  },
  rollingColumns: {
    Status: 'Status',
    Customer: 'Merchant'
  },
  rollingDetailLabels: {
    Customer: 'Merchant ID'
  },
  blockchainColumns: {
    ID: 'Id in DB. Important: this ID is used to re-submit transaction on blockchain in case of errors.',
  },
  detailLabels: {
    Customer: 'Accepted by customer',
    'Ext. ID': 'TX ID on merchant side',
    'External ID': 'TX ID on merchant side',
    'Requested by': 'Merchant ID and Email',
    Finished: 'Payment finished'
  },
  antifrodLabels: {
    'Scorechain (in)': `
      <30 - Risky address, order rejected address added to blacklist <br />
      =>30 <50 - middle risk, order receives status SUSPICIOUS <br />
      =>50 - address is not risky (at this moment) 
    `,
    'Scorechain (out)': `
      <30 - Risky address, order rejected address added to blacklist <br />
      =>30 <50 - middle risk, order receives status SUSPICIOUS <br />
      =>50 - address is not risky (at this moment) 
    `,
    Seon: `
      Approve - tx OK <br />
      Decline, Review - Order receives status SUSPICIOUS
    `
  },
  paymentOperationTypes: {
    BLOCK: 'BLOCK',
    POSTBACK: 'Postback receiving',
    CHARGE: 'Money charging'
  },
  customerSettings: {
    Withdrawal: 'Withdrawal fee',
    'Withdrawal fixed': 'Withdrawal fixed fee',
    Deposit: 'Deposit fee',
    'Deposit fixed': 'Deposit fixed fee',
    Rolling: 'Rolling fee',
    'Merchant deposit': 'Merchant deposit fee',
    'Merchant deposit fixed': 'Merchant deposit fixed fee',
    URL: 'Postback URL',
    'Per day': 'Daily SMS limit',
    'OBI Transaction Type Preferences': 
     `Only Instant banks - only show banks that support instant payments,\n
      Instant preferable (All SEPA + Instant) - show all banks but send via SEPA Instant if possible,\n
      Only SEPA Credit (All SEPA + Instant) - show all banks and send via the no-cost option (SEPA Credit)`,
    FindOrdersWithinMA: 'Find all orders within MA',
    'Display all orders for this Master Account (Order/find)':
     `If the setting is active, the order/find request with this SID\n
      will filter orders across the entire Master Account associated with this SID`,
    Replacement: 'Replacing current merchant with merchant to be replaced'
  },
  filters: {
    clearsDate: 'If this field is not empty, dates will be ignored',
    multi: 'Multi-select'
  },
  masterAccount: {
    master: 'Master Account',
    subAccount: 'Linked with Master Account',
  },
  limits: {
    OBI: 'Total volume for status EXECUTED',
  }
};

export default descriptions;
