import TableFilter from '../../../components/Filter';
import { AccountName } from '../../../api/bookkeeper';
import { AutocompleteFieldWithTags, Select } from '../../../components/Form';

interface IFilter {
  accounts?: string[];
  subAccounts?: string[];
}

interface IBookkeeperBalanceFilterProps {
  filter: IFilter,
  onChange: (filter: IFilter) => void
}

export default function BookkeeperBalanceFilter({ filter, onChange }: IBookkeeperBalanceFilterProps) {
  return (
    <TableFilter onApply={onChange} filter={filter}>
      <Select
        multi
        name="accounts"
        label="Accounts"
        options={Object.values(AccountName).map(account => ({ value: account, label: account }))}
      />
      <AutocompleteFieldWithTags
        name="subAccounts"
        label="Sub Accounts"
      />
    </TableFilter>
  );
}
